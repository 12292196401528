export const IconDropdownLarge = () => <svg xmlns="http://www.w3.org/2000/svg" width="14.751" height="8.409" viewBox="0 0 14.751 8.409">
  <path id="Path_33" data-name="Path 33" d="M105.245,8.106,98.9,14.448a1.033,1.033,0,0,1-1.461-1.461l5.612-5.612L97.441,1.763A1.033,1.033,0,0,1,98.9.3l6.343,6.343a1.033,1.033,0,0,1,0,1.461Z" transform="translate(14.751 -97.138) rotate(90)" fill="#1b214a"/>
</svg>

export const IconAccept = () => <svg style={{transform: "translateY(2px)"}} id="icon-accept" xmlns="http://www.w3.org/2000/svg" width="21.258" height="22.188" viewBox="0 0 21.258 22.188">
  <path id="Path_124" data-name="Path 124" d="M66.287,98.637h-3.88a.65.65,0,1,0,0,1.3h3.88a.65.65,0,1,0,0-1.3Zm0,0" transform="translate(-59.081 -94.362)" fill="#fff"/>
  <path id="Path_125" data-name="Path 125" d="M66.287,158.641h-3.88a.65.65,0,1,0,0,1.3h3.88a.65.65,0,1,0,0-1.3Zm0,0" transform="translate(-59.081 -151.766)" fill="#fff"/>
  <path id="Path_126" data-name="Path 126" d="M66.287,218.645h-3.88a.65.65,0,1,0,0,1.3h3.88a.65.65,0,1,0,0-1.3Zm0,0" transform="translate(-59.081 -209.169)" fill="#fff"/>
  <path id="Path_127" data-name="Path 127" d="M66.287,278.648h-3.88a.65.65,0,0,0,0,1.3h3.88a.65.65,0,0,0,0-1.3Zm0,0" transform="translate(-59.081 -266.573)" fill="#fff"/>
  <path id="Path_128" data-name="Path 128" d="M66.287,339.656h-3.88a.65.65,0,1,0,0,1.3h3.88a.65.65,0,1,0,0-1.3Zm0,0" transform="translate(-59.081 -324.937)" fill="#fff"/>
  <path id="Path_129" data-name="Path 129" d="M202.306,99.937h3.88a.65.65,0,1,0,0-1.3h-3.88a.65.65,0,0,0,0,1.3Zm0,0" transform="translate(-192.917 -94.362)" fill="#fff"/>
  <path id="Path_130" data-name="Path 130" d="M202.306,159.941h3.88a.65.65,0,1,0,0-1.3h-3.88a.65.65,0,0,0,0,1.3Zm0,0" transform="translate(-192.917 -151.766)" fill="#fff"/>
  <path id="Path_131" data-name="Path 131" d="M204.825,219.295a.65.65,0,0,0-.65-.65h-1.869a.65.65,0,0,0,0,1.3h1.869A.65.65,0,0,0,204.825,219.295Zm0,0" transform="translate(-192.917 -209.169)" fill="#fff"/>
  <path id="Path_132" data-name="Path 132" d="M314.088,342.831a.65.65,0,0,0-.914.1l-1.674,2.087-1.037-.751a.65.65,0,1,0-.763,1.053l1.538,1.114a.649.649,0,0,0,.888-.12l2.062-2.57A.65.65,0,0,0,314.088,342.831Zm0,0" transform="translate(-296.023 -327.837)" fill="#fff"/>
  <path id="Path_133" data-name="Path 133" d="M19.708,13a5.361,5.361,0,0,0-3.114-1.565V2.618A2.621,2.621,0,0,0,13.977,0H2.618A2.621,2.621,0,0,0,0,2.618V17.676a2.621,2.621,0,0,0,2.618,2.618h9.136a5.351,5.351,0,0,0,4.105,1.894A5.4,5.4,0,0,0,19.708,13ZM1.3,17.676V2.618A1.32,1.32,0,0,1,2.618,1.3H13.977a1.319,1.319,0,0,1,1.318,1.318v8.795a5.406,5.406,0,0,0-4.364,7.581H2.618A1.319,1.319,0,0,1,1.3,17.676Zm14.559,3.212a4.1,4.1,0,0,1,0-8.2h.074a4.1,4.1,0,0,1-.074,8.2Zm0,0" fill="#fff"/>
</svg>

export const IconArrowLeft = () => <svg xmlns="http://www.w3.org/2000/svg" width="13.132" height="9.037" viewBox="0 0 13.132 9.037">
  <path id="icon-arrow-lt" d="M12.953,46,9.045,42.1a.611.611,0,1,0-.864.864l2.865,2.865H.611a.611.611,0,0,0,0,1.221H11.047L8.182,49.911a.611.611,0,0,0,.864.864l3.908-3.908A.611.611,0,0,0,12.953,46Z" transform="translate(13.132 50.953) rotate(180)"/>
</svg>

export const IconArrowRight = () => <svg xmlns="http://www.w3.org/2000/svg" width="13.132" height="9.037" viewBox="0 0 13.132 9.037">
  <path id="icon-arrow-right" d="M12.953,46,9.045,42.1a.611.611,0,1,0-.864.864l2.865,2.865H.611a.611.611,0,0,0,0,1.221H11.047L8.182,49.911a.611.611,0,0,0,.864.864l3.908-3.908A.611.611,0,0,0,12.953,46Z" transform="translate(0 -41.916)"/>
</svg>

export const IconArrowRt = () => <svg xmlns="http://www.w3.org/2000/svg" width="13.132" height="9.037" viewBox="0 0 13.132 9.037">
  <path id="icon-arrow-rt" d="M12.953,46,9.045,42.1a.611.611,0,1,0-.864.864l2.865,2.865H.611a.611.611,0,0,0,0,1.221H11.047L8.182,49.911a.611.611,0,0,0,.864.864l3.908-3.908A.611.611,0,0,0,12.953,46Z" transform="translate(0 -41.916)"/>
</svg>

export const IconB2t = () => <svg xmlns="http://www.w3.org/2000/svg" width="14.752" height="8.409" viewBox="0 0 14.752 8.409">
  <path id="icon-b2t" d="M105.245,8.106,98.9,14.448a1.033,1.033,0,0,1-1.461-1.461l5.612-5.612L97.441,1.763A1.033,1.033,0,0,1,98.9.3l6.343,6.343a1.033,1.033,0,0,1,0,1.461Z" transform="translate(0 105.547) rotate(-90)" fill="#fff"/>
</svg>

export const IconCash = () => <svg xmlns="http://www.w3.org/2000/svg" width="40.616" height="24" viewBox="0 0 40.616 24">
  <g id="cash" transform="translate(0 -23.272)">
    <path id="Path_48" data-name="Path 48" d="M38.77,47.272H1.846A1.852,1.852,0,0,1,0,45.426V25.118a1.852,1.852,0,0,1,1.846-1.846H38.77a1.852,1.852,0,0,1,1.846,1.846V45.426A1.852,1.852,0,0,1,38.77,47.272Z" transform="translate(0 0)" fill="#0e9347"/>
    <path id="Path_50" data-name="Path 50" d="M56.5,66.854H26.965a3.7,3.7,0,0,0-3.692-3.692V50.238a3.7,3.7,0,0,0,3.692-3.692H56.5A3.7,3.7,0,0,0,60.2,50.238V63.162A3.7,3.7,0,0,0,56.5,66.854Z" transform="translate(-21.427 -21.428)" fill="#3bb54a"/>
    <g id="Group_97" data-name="Group 97" transform="translate(7.385 26.964)">
      <ellipse id="Ellipse_8" data-name="Ellipse 8" cx="7.385" cy="8.308" rx="7.385" ry="8.308" transform="translate(5.539 0)" fill="#0e9347"/>
      <circle id="Ellipse_9" data-name="Ellipse 9" cx="1.846" cy="1.846" r="1.846" transform="translate(0 6.462)" fill="#0e9347"/>
      <circle id="Ellipse_10" data-name="Ellipse 10" cx="1.846" cy="1.846" r="1.846" transform="translate(22.154 6.462)" fill="#0e9347"/>
    </g>
    <path id="Path_52" data-name="Path 52" d="M214.07,109.344h-1.846a.923.923,0,0,1,0-1.846h2.769a.923.923,0,1,0,0-1.846h-.923a.923.923,0,1,0-1.846,0,2.769,2.769,0,1,0,0,5.539h1.846a.923.923,0,0,1,0,1.846H211.3a.923.923,0,1,0,0,1.846h.923a.923.923,0,1,0,1.846,0,2.769,2.769,0,1,0,0-5.539Z" transform="translate(-192.839 -74.995)" fill="#89c763"/>
  </g>
</svg>

export const IconChecked = () => <svg xmlns="http://www.w3.org/2000/svg" width="20.172" height="15.517" viewBox="0 0 20.172 15.517">
  <path id="icon-checked" d="M.233,11.534a.75.75,0,0,1,0-1.086L1.319,9.362a.75.75,0,0,1,1.086,0l.078.078L6.75,14.017a.375.375,0,0,0,.543,0l10.4-10.784h.078a.75.75,0,0,1,1.086,0l1.086,1.086a.75.75,0,0,1,0,1.086h0L7.526,18.284a.75.75,0,0,1-1.086,0L.388,11.767l-.155-.233Z" transform="translate(0 -3)"/>
</svg>

export const IconCheckedC = () => <svg xmlns="http://www.w3.org/2000/svg" width="24.439" height="24.439" viewBox="0 0 24.439 24.439">
  <g id="icon-checked-c" transform="translate(6.232 7.735)">
    <path id="Path_101" data-name="Path 101" d="M119.37,135.04l-5.914,6.024-3.031-3.043-1.625,1.625,4.778,4.766,7.674-7.442Z" transform="translate(-108.8 -135.04)" fill="#cbcbcb"/>
    <path id="Path_102" data-name="Path 102" d="M12.22,0a12.22,12.22,0,1,0,12.22,12.22A12.22,12.22,0,0,0,12.22,0Zm0,22.228A10.008,10.008,0,1,1,22.228,12.22,10.008,10.008,0,0,1,12.22,22.228Z" transform="translate(-6.232 -7.735)" fill="#cbcbcb"/>
  </g>
</svg>

export const IconCheckedCa = () => <svg xmlns="http://www.w3.org/2000/svg" width="24.439" height="24.439" viewBox="0 0 24.439 24.439">
  <g id="icon-checked-ca" transform="translate(6.232 7.735)">
    <path id="Path_101" data-name="Path 101" d="M119.37,135.04l-5.914,6.024-3.031-3.043-1.625,1.625,4.778,4.766,7.674-7.442Z" transform="translate(-108.8 -135.04)" fill="#33b92d"/>
    <path id="Path_102" data-name="Path 102" d="M12.22,0a12.22,12.22,0,1,0,12.22,12.22A12.22,12.22,0,0,0,12.22,0Zm0,22.228A10.008,10.008,0,1,1,22.228,12.22,10.008,10.008,0,0,1,12.22,22.228Z" transform="translate(-6.232 -7.735)" fill="#33b92d"/>
  </g>
</svg>

export const IconCheckout = () => <svg style={{transform: "translateY(2px);"}} xmlns="http://www.w3.org/2000/svg" width="23.296" height="17.907" viewBox="0 0 23.296 17.907">
  <g id="icon-checkout" transform="translate(9.453 8.92)">
    <path id="Path_22" data-name="Path 22" d="M213.786,255.265a2.665,2.665,0,0,0-1.672.588,2.679,2.679,0,1,0,0,4.181,2.678,2.678,0,1,0,1.672-4.769Zm-2.378,3.91a1.568,1.568,0,1,1,0-2.462,2.669,2.669,0,0,0,0,2.462Zm2.378.336a1.567,1.567,0,1,1,1.567-1.567A1.569,1.569,0,0,1,213.786,259.511Z" transform="translate(-207.76 -255.265)" fill="#1b214a"/>
    <path id="Path_21" data-name="Path 21" d="M21.351,64.508H18.98V61.162a1.947,1.947,0,0,0-1.945-1.945H1.945A1.947,1.947,0,0,0,0,61.162v8.726a1.947,1.947,0,0,0,1.945,1.945H4.315v3.346A1.947,1.947,0,0,0,6.26,77.124H21.351A1.947,1.947,0,0,0,23.3,75.179V66.453A1.947,1.947,0,0,0,21.351,64.508ZM1.111,61.162a.834.834,0,0,1,.834-.834H17.036a.834.834,0,0,1,.834.834v.776H1.111Zm3.2,5.291v4.269H1.945a.834.834,0,0,1-.834-.833V65.623H4.5A1.932,1.932,0,0,0,4.315,66.453Zm-3.2-1.941V63.049H17.869v1.459H6.26c-.038,0-.075,0-.112,0Zm20.24,11.5H6.26a.834.834,0,0,1-.834-.834V66.453a.834.834,0,0,1,.765-.83H18.425a.556.556,0,0,0,.061,0h2.866a.834.834,0,0,1,.834.834v8.726h0A.834.834,0,0,1,21.351,76.013Z" transform="translate(-9.453 -68.137)" fill="#1b214a"/>
  </g>
</svg>

export const IconCheckoutActive = () => <svg style={{transform: "translateY(2px);"}} xmlns="http://www.w3.org/2000/svg" width="23.296" height="17.907" viewBox="0 0 23.296 17.907">
  <path id="icon-checkout" d="M21.351,64.508H18.98V61.162a1.947,1.947,0,0,0-1.945-1.945H1.945A1.947,1.947,0,0,0,0,61.162v8.726a1.947,1.947,0,0,0,1.945,1.945H4.315v3.346A1.947,1.947,0,0,0,6.26,77.124H21.351A1.947,1.947,0,0,0,23.3,75.179V66.453A1.947,1.947,0,0,0,21.351,64.508ZM1.111,61.162a.834.834,0,0,1,.834-.834H17.036a.834.834,0,0,1,.834.834v.776H1.111Zm3.2,5.291v4.269H1.945a.834.834,0,0,1-.834-.833V65.623H4.5A1.932,1.932,0,0,0,4.315,66.453Zm-3.2-1.941V63.049H17.869v1.459H6.26c-.038,0-.075,0-.112,0Zm20.24,11.5H6.26a.834.834,0,0,1-.834-.834V66.453a.834.834,0,0,1,.765-.83H18.425a.556.556,0,0,0,.061,0h2.866a.834.834,0,0,1,.834.834v8.726h0A.834.834,0,0,1,21.351,76.013Z" transform="translate(0 -59.217)" fill="#ffffff"/>
</svg>

export const IconClock = () => <svg xmlns="http://www.w3.org/2000/svg" width="16.583" height="16.574" viewBox="0 0 16.583 16.574">
  <g id="icon-clock" transform="translate(10 0)">
    <path id="Path_35" data-name="Path 35" d="M288.118,116.125v-4.7a.523.523,0,0,0-.526-.508.511.511,0,0,0-.5.508V116.2a.414.414,0,0,1,.018.073.474.474,0,0,0,.136.408l2.711,2.711a.516.516,0,0,0,.7,0,.489.489,0,0,0,0-.707Z" transform="translate(-289.314 -107.92)"/>
    <path id="Path_34" data-name="Path 34" d="M15.921,5.227A8.124,8.124,0,0,0,11.506.83,8.15,8.15,0,0,0,8.278.168,8.066,8.066,0,0,0,5.059.83a8.15,8.15,0,0,0-4.406,4.4,8.284,8.284,0,0,0,0,6.446A8.167,8.167,0,0,0,5.059,16.08a8.066,8.066,0,0,0,3.219.662,8.153,8.153,0,0,0,3.228-.662,8.142,8.142,0,0,0,4.415-4.406,8.179,8.179,0,0,0,0-6.446ZM14.6,12.109a7.274,7.274,0,0,1-2.666,2.656,7.141,7.141,0,0,1-3.654.979,7.021,7.021,0,0,1-2.829-.58,7.413,7.413,0,0,1-2.321-1.55,7.369,7.369,0,0,1-1.55-2.33,7.138,7.138,0,0,1-.571-2.838A7.128,7.128,0,0,1,1.977,4.8,7.278,7.278,0,0,1,4.633,2.145a7.086,7.086,0,0,1,3.644-.979,7.141,7.141,0,0,1,3.654.979A7.3,7.3,0,0,1,14.6,4.8a7.086,7.086,0,0,1,.979,3.645A7.155,7.155,0,0,1,14.6,12.109Z" transform="translate(-10 -0.168)"/>
  </g>
</svg>


export const IconClose = () => <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
  <g><path d="M638.6,500l322.7-322.7c38.3-38.3,38.3-100.3,0-138.6C923,0.4,861,0.4,822.7,38.7L500,361.4L177.3,38.7C139,0.4,77,0.4,38.7,38.7C0.4,77,0.4,139,38.7,177.3L361.4,500L38.7,822.7C0.4,861,0.4,923,38.7,961.3C57.9,980.4,82.9,990,108,990s50.1-9.6,69.3-28.7L500,638.6l322.7,322.7c19.1,19.1,44.2,28.7,69.3,28.7c25.1,0,50.1-9.6,69.3-28.7c38.3-38.3,38.3-100.3,0-138.6L638.6,500z"/></g>
</svg>

export const IconConfirm = () => <svg style={{transform: "translateY(2px);"}} xmlns="http://www.w3.org/2000/svg" width="19.467" height="18.615" viewBox="0 0 19.467 18.615">
  <g id="icon-confirm" transform="translate(5.965 0.857)">
    <path id="Path_13" data-name="Path 13" d="M17.828,18.939a.786.786,0,0,0-.786.786v.792a7.735,7.735,0,0,1-7.735,7.73h0a7.735,7.735,0,0,1,0-15.469h0a7.687,7.687,0,0,1,3.144.67.786.786,0,0,0,.64-1.437,9.249,9.249,0,0,0-3.783-.806H9.307A9.307,9.307,0,0,0,9.3,29.821h.005a9.307,9.307,0,0,0,9.307-9.3v-.792A.786.786,0,0,0,17.828,18.939Z" transform="translate(-5.965 -12.063)" fill="#1B214A"/>
    <path id="Path_14" data-name="Path 14" d="M170.151,33.976a.786.786,0,0,0-1.112,0l-8.817,8.817-2-2a.786.786,0,0,0-1.112,1.112l2.556,2.556a.786.786,0,0,0,1.112,0l9.373-9.373A.786.786,0,0,0,170.151,33.976Z" transform="translate(-156.879 -33.746)" fill="#1B214A"/>
  </g>
</svg>

export const IconConfirmActive = () => <svg style={{transform: "translateY(2px);"}} xmlns="http://www.w3.org/2000/svg" width="19.467" height="18.615" viewBox="0 0 19.467 18.615">
  <g id="icon-confirm" transform="translate(5.965 0.857)">
    <path id="Path_13" data-name="Path 13" d="M17.828,18.939a.786.786,0,0,0-.786.786v.792a7.735,7.735,0,0,1-7.735,7.73h0a7.735,7.735,0,0,1,0-15.469h0a7.687,7.687,0,0,1,3.144.67.786.786,0,0,0,.64-1.437,9.249,9.249,0,0,0-3.783-.806H9.307A9.307,9.307,0,0,0,9.3,29.821h.005a9.307,9.307,0,0,0,9.307-9.3v-.792A.786.786,0,0,0,17.828,18.939Z" transform="translate(-5.965 -12.063)" fill="#ffffff"/>
    <path id="Path_14" data-name="Path 14" d="M170.151,33.976a.786.786,0,0,0-1.112,0l-8.817,8.817-2-2a.786.786,0,0,0-1.112,1.112l2.556,2.556a.786.786,0,0,0,1.112,0l9.373-9.373A.786.786,0,0,0,170.151,33.976Z" transform="translate(-156.879 -33.746)" fill="#ffffff"/>
  </g>
</svg>

export const IconDate = () => <svg style={{marginBottom: "3px;"}} xmlns="http://www.w3.org/2000/svg" width="17.104" height="17.104" viewBox="0 0 17.104 17.104">
  <g id="icon-date" transform="translate(0)">
    <path id="Path_25" data-name="Path 25" d="M15.1,1.336h-.8V0H12.961V1.336H4.142V0H2.806V1.336H2a2.007,2.007,0,0,0-2,2V15.1a2.007,2.007,0,0,0,2,2H15.1a2.007,2.007,0,0,0,2-2V3.341A2.007,2.007,0,0,0,15.1,1.336ZM15.767,15.1a.669.669,0,0,1-.668.668H2a.669.669,0,0,1-.668-.668V6.28H15.767Zm0-10.155H1.336v-1.6A.669.669,0,0,1,2,2.672h.8V4.009H4.142V2.672h8.819V4.009H14.3V2.672h.8a.669.669,0,0,1,.668.668Z" transform="translate(0)" fill="#a5a5a5"/>
    <rect id="Rectangle_11" data-name="Rectangle 11" width="1" height="1" transform="translate(3.553 8)" fill="#a5a5a5"/>
    <rect id="Rectangle_12" data-name="Rectangle 12" width="1" height="1" transform="translate(6.553 8)" fill="#a5a5a5"/>
    <rect id="Rectangle_16" data-name="Rectangle 16" width="1" height="1" transform="translate(3.553 11)" fill="#a5a5a5"/>
    <rect id="Rectangle_17" data-name="Rectangle 17" width="1" height="1" transform="translate(6.553 11)" fill="#a5a5a5"/>
    <rect id="Rectangle_20" data-name="Rectangle 20" width="1" height="1" transform="translate(3.553 14)" fill="#a5a5a5"/>
    <rect id="Rectangle_21" data-name="Rectangle 21" width="1" height="1" transform="translate(6.553 14)" fill="#a5a5a5"/>
    <rect id="Rectangle_29" data-name="Rectangle 29" width="1" height="1" transform="translate(9.553 8)" fill="#a5a5a5"/>
    <rect id="Rectangle_30" data-name="Rectangle 30" width="1" height="1" transform="translate(9.553 11)" fill="#a5a5a5"/>
    <rect id="Rectangle_31" data-name="Rectangle 31" width="1" height="1" transform="translate(9.553 14)" fill="#a5a5a5"/>
    <rect id="Rectangle_32" data-name="Rectangle 32" width="1" height="1" transform="translate(12.553 8)" fill="#a5a5a5"/>
    <rect id="Rectangle_33" data-name="Rectangle 33" width="1" height="1" transform="translate(12.553 11)" fill="#a5a5a5"/>
  </g>
</svg>


export const IconDownload = () => <svg xmlns="http://www.w3.org/2000/svg" width="22.039" height="22.039" viewBox="0 0 22.039 22.039">
  <path id="icon-download" d="M17.639,11.988l-6.62,6.62L4.4,11.988l1.218-1.218,4.541,4.541V0H11.88V15.312l4.541-4.541Zm4.4,8.329H0v1.722H22.039Zm0,0" fill="#fff"/>
</svg>


export const IconDropdown = () => <svg xmlns="http://www.w3.org/2000/svg" width="11.289" height="6.435" viewBox="0 0 11.289 6.435">
  <path id="icon-dropdown" d="M6.2,6.2,1.349,11.057A.79.79,0,0,1,.231,9.939L4.526,5.644.232,1.35A.791.791,0,0,1,1.35.232L6.2,5.085A.79.79,0,0,1,6.2,6.2Z" transform="translate(11.288 0) rotate(90)" fill="#1B214A"/>
</svg>


export const IconDropdownTicket = () => <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10">
  <path id="Path_33" data-name="Path 33" d="M5.784,5.5l-4.526,4.3a.764.764,0,0,1-1.042,0,.676.676,0,0,1,0-.99l4-3.8-4-3.8a.676.676,0,0,1,0-.99.765.765,0,0,1,1.042,0L5.784,4.5a.676.676,0,0,1,0,.99Z" transform="translate(0)" fill="#1b214a"/>
</svg>


export const IconEmail = () => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
  <path id="icon-email" d="M18.242,61H1.758A1.747,1.747,0,0,0,0,62.731V74.269A1.747,1.747,0,0,0,1.758,76H18.242A1.747,1.747,0,0,0,20,74.269V62.731A1.747,1.747,0,0,0,18.242,61ZM18,62.154l-7.962,7.84-8.031-7.84ZM1.172,74.03V62.964l5.644,5.509ZM2,74.846l5.647-5.56,1.979,1.932a.592.592,0,0,0,.827,0l1.929-1.9L18,74.846Zm16.828-.816L13.211,68.5l5.617-5.53Z" transform="translate(0 -61)" fill="#1b214a"/>
</svg>


export const IconInfo = () => <svg id="icon-info" xmlns="http://www.w3.org/2000/svg" width="22.926" height="22.925" viewBox="0 0 22.926 22.925">
  <path id="Path_98" data-name="Path 98" d="M11.463,0A11.463,11.463,0,1,0,22.925,11.463,11.476,11.476,0,0,0,11.463,0Zm0,20.841a9.379,9.379,0,1,1,9.379-9.378A9.389,9.389,0,0,1,11.463,20.841Z"/>
  <path id="Path_99" data-name="Path 99" d="M146.391,70a1.389,1.389,0,1,0,1.389,1.39A1.391,1.391,0,0,0,146.391,70Z" transform="translate(-134.929 -65.137)"/>
  <path id="Path_100" data-name="Path 100" d="M151.042,140A1.042,1.042,0,0,0,150,141.042v6.252a1.042,1.042,0,1,0,2.084,0v-6.252A1.042,1.042,0,0,0,151.042,140Z" transform="translate(-139.579 -130.274)"/>
</svg>


export const IconLang = () => <svg xmlns="http://www.w3.org/2000/svg" width="18.099" height="18.099" viewBox="0 0 18.099 18.099">
  <path id="icon-lang" d="M9.049,0A9.049,9.049,0,1,0,18.1,9.049,9.06,9.06,0,0,0,9.049,0ZM6.107,1.751A9.173,9.173,0,0,0,4.867,4.238a8.417,8.417,0,0,1-1.453-.675A7.9,7.9,0,0,1,6.107,1.751ZM2.647,4.479a9.54,9.54,0,0,0,1.9.895,15.8,15.8,0,0,0-.4,3.085H1.2A7.823,7.823,0,0,1,2.647,4.479Zm0,9.141A7.823,7.823,0,0,1,1.2,9.64H4.142a15.8,15.8,0,0,0,.4,3.085A9.542,9.542,0,0,0,2.647,13.62Zm.766.915a8.417,8.417,0,0,1,1.453-.675,9.173,9.173,0,0,0,1.241,2.487A7.9,7.9,0,0,1,3.414,14.535ZM8.459,16.81C7.24,16.362,6.419,14.76,6,13.526a13,13,0,0,1,2.461-.332Zm0-4.8a14.16,14.16,0,0,0-2.782.379A14.63,14.63,0,0,1,5.322,9.64H8.459v2.373Zm0-3.553H5.322a14.625,14.625,0,0,1,.355-2.752,14.16,14.16,0,0,0,2.782.379Zm0-3.554A13,13,0,0,1,6,4.573C6.419,3.34,7.24,1.737,8.459,1.289Zm6.992-.427A7.823,7.823,0,0,1,16.9,8.459H13.957a15.8,15.8,0,0,0-.4-3.085A9.542,9.542,0,0,0,15.451,4.479Zm-.766-.915a8.417,8.417,0,0,1-1.453.675,9.173,9.173,0,0,0-1.241-2.487A7.9,7.9,0,0,1,14.685,3.564ZM9.64,1.289c1.219.447,2.04,2.05,2.461,3.284a13,13,0,0,1-2.461.332Zm0,4.8a14.16,14.16,0,0,0,2.782-.379,14.63,14.63,0,0,1,.355,2.752H9.64Zm0,3.549h3.137a14.667,14.667,0,0,1-.355,2.756,14.16,14.16,0,0,0-2.782-.379Zm0,7.174V13.193a13,13,0,0,1,2.461.332C11.68,14.759,10.859,16.362,9.64,16.81Zm2.352-.462a9.173,9.173,0,0,0,1.241-2.487,8.418,8.418,0,0,1,1.453.675A7.9,7.9,0,0,1,11.992,16.347Zm3.46-2.727a9.54,9.54,0,0,0-1.9-.895,15.842,15.842,0,0,0,.4-3.089H16.9A7.836,7.836,0,0,1,15.451,13.62Z" transform="translate(0)" fill="#a5a5a5"/>
</svg>


export const IconLocation = () => <svg xmlns="http://www.w3.org/2000/svg" width="15.643" height="19.335" viewBox="0 0 15.643 19.335">
  <g id="icon-location" transform="translate(4.036 4.011)">
    <path id="Path_15" data-name="Path 15" d="M63.125,3.362a7.807,7.807,0,0,0-12.836,0,7.808,7.808,0,0,0-.909,7.194,6.149,6.149,0,0,0,1.133,1.871l5.679,6.67a.676.676,0,0,0,1.029,0L62.9,12.429a6.155,6.155,0,0,0,1.133-1.869A7.809,7.809,0,0,0,63.125,3.362Zm-.359,6.725a4.823,4.823,0,0,1-.892,1.461l0,0-5.164,6.066L51.54,11.547a4.825,4.825,0,0,1-.893-1.465A6.459,6.459,0,0,1,51.4,4.131a6.455,6.455,0,0,1,10.611,0A6.46,6.46,0,0,1,62.767,10.086Z" transform="translate(-52.922 -4.011)" fill="#a5a5a5"/>
    <path id="Path_16" data-name="Path 16" d="M159.54,106.219A3.786,3.786,0,1,0,163.326,110,3.79,3.79,0,0,0,159.54,106.219Zm0,6.22A2.434,2.434,0,1,1,161.974,110,2.437,2.437,0,0,1,159.54,112.439Z" transform="translate(-155.754 -106.219)" fill="#a5a5a5"/>
  </g>
</svg>


export const IconPhone = () => <svg xmlns="http://www.w3.org/2000/svg" width="20.31" height="20.311" viewBox="0 0 20.31 20.311">
  <path id="icon-phone" d="M19.8,14.98l-2.525-2.525a1.787,1.787,0,0,0-2.525,0L13.6,13.6a1.354,1.354,0,0,1-1.913,0L6.714,8.625a1.352,1.352,0,0,1,0-1.913L7.861,5.564a1.784,1.784,0,0,0,0-2.526L5.336.522a1.787,1.787,0,0,0-2.523,0l-.92.912a6.445,6.445,0,0,0,0,9.1l7.882,7.886a6.434,6.434,0,0,0,9.1,0l.918-.918A1.787,1.787,0,0,0,19.8,14.98ZM3.653,1.364a.6.6,0,0,1,.842,0L7.02,3.881a.6.6,0,0,1,0,.842L6.6,5.143,3.234,1.779Zm6.964,16.218L2.735,9.7a5.232,5.232,0,0,1-.328-7.061L5.761,5.989a2.54,2.54,0,0,0,.111,3.477l4.974,4.979h0a2.54,2.54,0,0,0,3.477.111l3.355,3.355A5.222,5.222,0,0,1,10.617,17.582Zm8.34-.918-.421.421-3.367-3.367.421-.421a.6.6,0,0,1,.842,0l2.525,2.525A.6.6,0,0,1,18.957,16.664Z" transform="translate(-0.011 0)" fill="#1b214a"/>
</svg>


export const IconRefresh = () => <svg xmlns="http://www.w3.org/2000/svg" width="13.799" height="14.86" viewBox="0 0 13.799 14.86">
  <g id="icon-refresh" transform="translate(1.061 6.899)">
    <path id="Path_6" data-name="Path 6" d="M29.273,0a.531.531,0,0,0-.531.531V2.469A6.886,6.886,0,0,0,17.067,7.43a.531.531,0,1,0,1.061,0A5.838,5.838,0,0,1,28.272,3.489l-2.351.784a.531.531,0,1,0,.335,1.008L29.441,4.22a.531.531,0,0,0,.363-.505V.531A.531.531,0,0,0,29.273,0Z" transform="translate(-18.128 -6.899)" />
    <path id="Path_7" data-name="Path 7" d="M63.4,221.867a.531.531,0,0,0-.531.531,5.838,5.838,0,0,1-10.144,3.941l2.351-.784a.531.531,0,1,0-.335-1.008l-3.184,1.061a.531.531,0,0,0-.363.505V229.3a.531.531,0,1,0,1.061,0v-1.939A6.886,6.886,0,0,0,63.935,222.4.531.531,0,0,0,63.4,221.867Z" transform="translate(-51.198 -221.867)" />
  </g>
</svg>


export const IconSearch = () => <svg xmlns="http://www.w3.org/2000/svg" width="21.335" height="21.335" viewBox="0 0 21.335 21.335">
  <path id="Path_26" data-name="Path 26" d="M32.074,30.82l-5.129-5.129a8.984,8.984,0,1,0-1.258,1.258l5.129,5.125a.888.888,0,1,0,1.258-1.254ZM19.991,27.193a7.2,7.2,0,1,1,7.206-7.2A7.211,7.211,0,0,1,19.991,27.193Z" transform="translate(-11 -11)" fill="#fff"/>
</svg>


export const IconSignIn = () => <svg xmlns="http://www.w3.org/2000/svg" width="22.322" height="20.368" viewBox="0 0 22.322 20.368">
  <g id="icon-signin" transform="translate(0 -22.407)">
    <path id="Path_27" data-name="Path 27" d="M15.54,204.165l-2.388-1.684a.654.654,0,0,0-1.031.535v1.03H.654a.654.654,0,1,0,0,1.308H12.121v1.03a.655.655,0,0,0,1.031.535l2.388-1.684A.655.655,0,0,0,15.54,204.165Z" transform="translate(0 -172.109)" fill="#1b214a"/>
    <path id="Path_28" data-name="Path 28" d="M82.962,22.407A10.183,10.183,0,0,0,74.141,27.5a.654.654,0,1,0,1.132.655,8.876,8.876,0,1,1,0,8.875.654.654,0,1,0-1.132.655,10.185,10.185,0,1,0,8.821-15.277Z" transform="translate(-70.825)" fill="#1b214a"/>
  </g>
</svg>


export const IconTicket = () => <svg style={{transform: "translateY(-1px);"}} xmlns="http://www.w3.org/2000/svg" width="22.932" height="22.932" viewBox="0 0 22.932 22.932">
  <path id="icon-ticket" d="M20.1,5.743l-.475.475a2.055,2.055,0,0,1-2.907-2.907l.475-.475L14.353,0,0,14.353l2.836,2.836.475-.475A2.055,2.055,0,0,1,6.218,19.62l-.475.475,2.836,2.836L22.932,8.579ZM7.6,20.049a3.4,3.4,0,0,0-4.714-4.714L1.9,14.353,14.353,1.9l.982.982A3.4,3.4,0,0,0,20.049,7.6l.982.982L8.579,21.031Z" transform="translate(0)" fill="#1B214A"/>
</svg>


export const IconTicketActive = () => <svg style={{transform: "translateY(-1px);"}} xmlns="http://www.w3.org/2000/svg" width="22.932" height="22.932" viewBox="0 0 22.932 22.932">
  <path id="icon-ticket" d="M20.1,5.743l-.475.475a2.055,2.055,0,0,1-2.907-2.907l.475-.475L14.353,0,0,14.353l2.836,2.836.475-.475A2.055,2.055,0,0,1,6.218,19.62l-.475.475,2.836,2.836L22.932,8.579ZM7.6,20.049a3.4,3.4,0,0,0-4.714-4.714L1.9,14.353,14.353,1.9l.982.982A3.4,3.4,0,0,0,20.049,7.6l.982.982L8.579,21.031Z" transform="translate(0)" fill="#ffffff"/>
</svg>


export const IconTicketButton = () => <svg style={{transform: "translateY(3px)"}} id="icon-ticket-button" xmlns="http://www.w3.org/2000/svg" width="17.695" height="17.696" viewBox="0 0 17.695 17.696">
  <path id="Path_32" data-name="Path 32" d="M15.507,4.431,15.14,4.8A1.586,1.586,0,0,1,12.9,2.555l.367-.367L11.076,0,0,11.076l2.189,2.189.367-.367A1.586,1.586,0,0,1,4.8,15.14l-.367.367L6.62,17.7,17.7,6.62ZM5.862,15.471a2.624,2.624,0,0,0-3.637-3.637l-.758-.758,9.609-9.609.758.758a2.624,2.624,0,0,0,3.637,3.637l.758.758L6.62,16.229Z" fill="#fff"/>
  <rect id="Rectangle_39" data-name="Rectangle 39" width="1.037" height="1.041" transform="translate(9.599 7.36) rotate(-45)" fill="#fff"/>
  <rect id="Rectangle_40" data-name="Rectangle 40" width="1.037" height="1.041" transform="translate(8.127 5.887) rotate(-45)" fill="#fff"/>
  <rect id="Rectangle_41" data-name="Rectangle 41" width="1.037" height="1.041" transform="translate(11.072 8.833) rotate(-45)" fill="#fff"/>
</svg>


export const IconTours = () => <svg xmlns="http://www.w3.org/2000/svg" width="20.426" height="20.907" viewBox="0 0 20.426 20.907">
  <g id="icon-calendar" transform="translate(0 0)">
    <path id="Path_29"  d="M194.227,280.983a.617.617,0,0,0,.873,0l2.2-2.2a.617.617,0,0,0-.873-.873l-1.767,1.767-.806-.806a.617.617,0,0,0-.873.873Zm0,0" transform="translate(-184.931 -266.385)" fill="#1B214A"/>
    <path id="Path_30"  d="M135.006,209.618a4.9,4.9,0,1,0-4.9-4.9A4.906,4.906,0,0,0,135.006,209.618Zm0-8.567a3.666,3.666,0,1,1-3.666,3.666A3.67,3.67,0,0,1,135.006,201.051Zm0,0" transform="translate(-124.793 -191.657)" fill="#1B214A"/>
    <path id="Path_31"  d="M18.43,1.314H17.3v-.7a.617.617,0,0,0-1.235,0v.7H14.856v-.7a.617.617,0,0,0-1.235,0v.7H6.8v-.7a.617.617,0,0,0-1.235,0v.7H4.357v-.7a.617.617,0,0,0-1.235,0v.7H2a2,2,0,0,0-2,2v15.6a2,2,0,0,0,2,2H18.43a2,2,0,0,0,2-2V3.311A2,2,0,0,0,18.43,1.314Zm.762,17.6a.763.763,0,0,1-.762.762H2a.763.763,0,0,1-.762-.762V6.448H19.192ZM1.235,3.311A.763.763,0,0,1,2,2.549H3.122v.7a.617.617,0,0,0,1.235,0v-.7H5.57v.7a.617.617,0,0,0,1.235,0v-.7h6.817v.7a.617.617,0,0,0,1.235,0v-.7h1.213v.7a.617.617,0,0,0,1.235,0v-.7H18.43a.763.763,0,0,1,.762.762v1.9H1.235Zm0,0" transform="translate(0 0)" fill="#1B214A"/>
  </g>
</svg>

export const IconToursActive = () => <svg xmlns="http://www.w3.org/2000/svg" width="20.426" height="20.907" viewBox="0 0 20.426 20.907">
  <g id="icon-calendar" transform="translate(0 0)">
    <path id="Path_29" data-name="Path 29" d="M194.227,280.983a.617.617,0,0,0,.873,0l2.2-2.2a.617.617,0,0,0-.873-.873l-1.767,1.767-.806-.806a.617.617,0,0,0-.873.873Zm0,0" transform="translate(-184.931 -266.385)" fill="#ffffff"/>
    <path id="Path_30" data-name="Path 30" d="M135.006,209.618a4.9,4.9,0,1,0-4.9-4.9A4.906,4.906,0,0,0,135.006,209.618Zm0-8.567a3.666,3.666,0,1,1-3.666,3.666A3.67,3.67,0,0,1,135.006,201.051Zm0,0" transform="translate(-124.793 -191.657)" fill="#ffffff"/>
    <path id="Path_31" data-name="Path 31" d="M18.43,1.314H17.3v-.7a.617.617,0,0,0-1.235,0v.7H14.856v-.7a.617.617,0,0,0-1.235,0v.7H6.8v-.7a.617.617,0,0,0-1.235,0v.7H4.357v-.7a.617.617,0,0,0-1.235,0v.7H2a2,2,0,0,0-2,2v15.6a2,2,0,0,0,2,2H18.43a2,2,0,0,0,2-2V3.311A2,2,0,0,0,18.43,1.314Zm.762,17.6a.763.763,0,0,1-.762.762H2a.763.763,0,0,1-.762-.762V6.448H19.192ZM1.235,3.311A.763.763,0,0,1,2,2.549H3.122v.7a.617.617,0,0,0,1.235,0v-.7H5.57v.7a.617.617,0,0,0,1.235,0v-.7h6.817v.7a.617.617,0,0,0,1.235,0v-.7h1.213v.7a.617.617,0,0,0,1.235,0v-.7H18.43a.763.763,0,0,1,.762.762v1.9H1.235Zm0,0" transform="translate(0 0)" fill="#ffffff"/>
  </g>
</svg>

export const IconLetter = () => <svg xmlns="http://www.w3.org/2000/svg" width="58.137" height="55.411" viewBox="0 0 58.137 55.411">
  <g id="letter" transform="translate(0 -11.815)">
    <path id="Path_91" data-name="Path 91" d="M0,68.459,29.068,51.2,58.137,68.459V93.894H0Z" transform="translate(0 -34.843)" fill="#d07c40"/>
    <path id="Path_92" data-name="Path 92" d="M41.2,11.815H86.62a1.822,1.822,0,0,1,1.817,1.817V45.425a1.822,1.822,0,0,1-1.817,1.817H41.2a1.822,1.822,0,0,1-1.817-1.817V13.632A1.822,1.822,0,0,1,41.2,11.815Z" transform="translate(-34.842)" fill="#efefef"/>
    <path id="Path_93" data-name="Path 93" d="M82.806,15.449l2.907-3.634h.908a1.822,1.822,0,0,1,1.817,1.817v1.817Zm-23.618,0,2.907-3.634h6.359l-2.907,3.634Zm-19.8,0V13.632A1.822,1.822,0,0,1,41.2,11.815h4.542l-2.907,3.634Z" transform="translate(-34.843 0)" fill="#26a6d1"/>
    <path id="Path_94" data-name="Path 94" d="M58.137,231.747a2.733,2.733,0,0,1-2.725,2.725H2.725A2.733,2.733,0,0,1,0,231.747V200.862l29.068,12.717,29.068-12.717v30.885Z" transform="translate(0 -167.246)" fill="#f7b141"/>
    <path id="Path_95" data-name="Path 95" d="M58.137,231.746a2.733,2.733,0,0,1-2.725,2.725H2.725A2.733,2.733,0,0,1,0,231.746V226.3l58.137-25.435Z" transform="translate(0 -167.245)" fill="#f6a31e"/>
    <path id="Path_96" data-name="Path 96" d="M109.421,115.046l.363,1.908-2.907-4.542-4.088,6.359c-.045.59,2.589,1.045,5.859,1.045s5.9-.454,5.9-1.045l-3.588-5.314Zm.363-2.5a1.135,1.135,0,1,0-1.135-1.135A1.124,1.124,0,0,0,109.784,112.548Zm9.311-.454h18.168a.908.908,0,1,0,0-1.817H119.1a.908.908,0,1,0,0,1.817Zm18.168,1.817H119.1a.908.908,0,1,0,0,1.817h18.168a.908.908,0,1,0,0-1.817Zm-4.542,3.633H119.1a.908.908,0,1,0,0,1.817h13.626a.908.908,0,1,0,0-1.817Z" transform="translate(-90.935 -87.107)" fill="#d6d9db"/>
    <path id="Path_97" data-name="Path 97" d="M132.32,15.449l2.907-3.634h6.359l-2.907,3.634Zm-23.618,0,2.907-3.634h6.359l-2.907,3.634Z" transform="translate(-96.166 0)" fill="#e2574c"/>
  </g>
</svg>


export const IconPhoneCall = () => <svg xmlns="http://www.w3.org/2000/svg" width="55.038" height="76.28" viewBox="0 0 55.038 76.28">
  <g id="phone-call" transform="translate(-71.287)">
    <g id="Group_106" data-name="Group 106" transform="translate(71.287 16.367)">
      <path id="Path_88" data-name="Path 88" d="M106.656,115.233a5.39,5.39,0,0,0-5.374-5.374H76.664a5.39,5.39,0,0,0-5.374,5.374v6.88l17.683,3.467,17.683-3.467Z" transform="translate(-71.29 -109.859)" fill="#e5e5e5"/>
      <path id="Path_89" data-name="Path 89" d="M71.29,416.822a5.39,5.39,0,0,0,5.374,5.374h24.618a5.39,5.39,0,0,0,5.374-5.374v-6.88l-17.683-3.467L71.29,409.942Z" transform="translate(-71.29 -362.284)" fill="#e5e5e5"/>
    </g>
    <rect id="Rectangle_97" data-name="Rectangle 97" width="35.366" height="35.404" transform="translate(71.287 28.621)" fill="#efefef"/>
    <path id="Path_90" data-name="Path 90" d="M215.975,15.623C215.975,6.995,207.167,0,196.3,0s-19.672,6.995-19.672,15.623c0,7.709,7.03,14.113,16.276,15.391h-.028a9.749,9.749,0,0,1-2.687,8.444,9.645,9.645,0,0,0,9.531-8.443h-.028C208.943,29.738,215.975,23.333,215.975,15.623Z" transform="translate(-89.649)" fill="#f6a31e"/>
    <g id="Group_107" data-name="Group 107" transform="translate(98.261 14.323)">
      <circle id="Ellipse_11" data-name="Ellipse 11" cx="1.3" cy="1.3" r="1.3" fill="#fff"/>
      <circle id="Ellipse_12" data-name="Ellipse 12" cx="1.3" cy="1.3" r="1.3" transform="translate(14.185)" fill="#fff"/>
      <circle id="Ellipse_13" data-name="Ellipse 13" cx="1.3" cy="1.3" r="1.3" transform="translate(7.092)" fill="#fff"/>
    </g>
  </g>
</svg>

