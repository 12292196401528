import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Input from '../../components/input';
import { emptyDate, routes, getStripePubKey } from '../../config/constants';
import { CancellationPolicy, OfferType, PickUpTime, PriceType, TimeSlotType } from '../../types/ticket';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import { ticketApi } from '../../lib/api';
import SelectRS from '../../components/select-rs';
import { Hotel } from '../../types/hotel';
import Payment from '../../components/payment';
import { Elements } from '@stripe/react-stripe-js';
import { BookingRequest } from '../../types/booking';
import { Availability } from '../../types/availability';
import { loadStripe } from '@stripe/stripe-js/pure';
import stripeJs from '@stripe/stripe-js';
import PhoneInput from 'react-phone-input-2';
import { declOfNum, timeFormat } from '../../lib/utils';
import { useTranslation } from 'react-i18next';

export type CheckoutData = {
  ticketName: string;
  offer: OfferType | undefined;
  selectedPrice?: PriceType;
  selectedDate?: Date;
  adultCount: number;
  childCount: number;
  infantCount: number;
  timeSlot?: TimeSlotType;
  hotel?: Hotel;
  pickUpTime?: PickUpTime;
  guestName?: string;
  userEmail?: string;
  userRoomNumber?: string;
  userPhone?: string;
  tourId?: number;
  tourUrl?: number;
  availability?: Availability;
  faq?: string;
  cancellationPolicy?: CancellationPolicy[];
};

const initialCheckout = {
  ticketName: '',
  offer: undefined,
  selectedDate: new Date(),
  adultCount: 1,
  childCount: 0,
  infantCount: 0
};

const validateEmail = (email: string) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [t] = useTranslation();


  // States
  const [hotels, setHotels] = useState<{ label: string, value: number, hotel: Hotel }[]>([]);
  const [checkout, setCheckout] = useState<CheckoutData>(initialCheckout);
  const [filterSelect, setFilterSelect] = useState('');
  const [stripePromise, setStripePromise] = useState<PromiseLike<stripeJs.Stripe | null> | stripeJs.Stripe | null>();
  const [isFormValid, setIsFormValid] = useState({ userEmail: true, userPhone: true, guestName: true, hotel: true });

  // Memos
  const filteredHotel = useMemo(() => {
    return hotels.filter((item) => item.label.toLowerCase().startsWith(filterSelect));
  }, [hotels, filterSelect]);

  const {amount, amountUsd} = useMemo(
    () =>({amount: checkout.adultCount * (checkout.selectedPrice?.pricePerAdult || 0) +
        checkout.childCount * (checkout.selectedPrice?.pricePerChild || 0) +
        checkout.infantCount * (checkout.selectedPrice?.pricePerInfant || 0) +
        (checkout.selectedPrice?.pricePerTrip || 0),
      amountUsd: checkout.adultCount * (checkout.selectedPrice?.pricePerAdultUsd || 0) +
        checkout.childCount * (checkout.selectedPrice?.pricePerChildUsd || 0) +
        checkout.infantCount * (checkout.selectedPrice?.pricePerInfantUsd || 0) +
        (checkout.selectedPrice?.pricePerTripUsd || 0)})
      ,
    [
      checkout.adultCount,
      checkout.childCount,
      checkout.infantCount,
      checkout.selectedPrice?.pricePerAdult,
      checkout.selectedPrice?.pricePerAdultUsd,
      checkout.selectedPrice?.pricePerChild,
      checkout.selectedPrice?.pricePerChildUsd,
      checkout.selectedPrice?.pricePerInfant,
      checkout.selectedPrice?.pricePerInfantUsd,
      checkout.selectedPrice?.pricePerTrip,
      checkout.selectedPrice?.pricePerTripUsd
    ]
  );

  const bookingRequest: BookingRequest = useMemo(
    () => ({
      adults: checkout.adultCount,
      children: checkout.childCount,
      date: checkout.selectedPrice?.date,
      endTime: checkout.timeSlot?.endTime,
      guestName: checkout.guestName || '',
      hotelId: checkout.hotel?.id,
      infants: checkout.infantCount,
      offerId: checkout.offer?.id,
      email: checkout.userEmail,
      paymentType: 1,
      roomNumber: checkout.userRoomNumber,
      startTime: checkout.timeSlot?.startTime,
      timeSlotId: checkout.timeSlot?.id,
      tourId: checkout.tourId,
      phone: checkout.userPhone,
      totalCost: amount,
      totalCostUsd: amountUsd
    }),
    [checkout, amount, amountUsd]
  );

  const ticketOnClickHandler = useCallback(() => navigate(`${routes.ticket}/${checkout.tourUrl}`, {state: checkout }), [checkout, navigate]);

  // Effects
  useEffect(() => {
    const _stripePromise = loadStripe(getStripePubKey());
    setStripePromise(_stripePromise);
  }, []);

  useEffect(() => {
    if (checkout.offer && !checkout.offer.isDirectReporting) {
      const fetchData = async () => {
        const data = await ticketApi.getHotels(50);
        setHotels(data.map((item) => ({ label: item.name, value: item.id, hotel: item })));
      };
      fetchData();
    }
    if (!location.state) {
      navigate(routes.ticket, {replace: true });
    }
    const data = location.state as CheckoutData;
    setCheckout(data);
  }, [checkout.offer, checkout.offer?.isDirectReporting, navigate, location.state]);

  useEffect(() => {
    document.addEventListener(
      'eventHeaderClick',
      () => {
        ticketOnClickHandler();
      },
      false
    );

    return () =>
      document.removeEventListener(
        'eventHeaderClick',
        () => {
          ticketOnClickHandler();
        },
        false
      );
  }, [ticketOnClickHandler]);

  useEffect(() => {
    if (checkout.timeSlot && checkout.hotel) {
      const fetchData = async () => {
        const data = await ticketApi.getPickUp(
          checkout.timeSlot?.pickUpTimeId,
          checkout.hotel?.id,
          checkout.timeSlot?.startTime
        );
        setFieldCheckoutHandler('pickUpTime', data);
      };
      fetchData();
    }
  }, [checkout.hotel, checkout.timeSlot]);

  // Callbacks
  const setFieldCheckoutHandler = (name: string, value: any) => {
    setCheckout((prev) => {
      if (prev) {
        let newState = { ...prev };
        // @ts-ignore
        newState[name] = value;
        return newState;
      }
      console.log('setFieldCheckoutHandler prev is null');
      return initialCheckout;
    });
    setIsFormValid((prev) => {
      let newState = { ...prev };
      if (name === 'userEmail') {
        // @ts-ignore
        newState[name] = !!validateEmail(value || '');
      } else {
        // @ts-ignore
        newState[name] = !!value;
      }
      return newState;
    });

  };

  const isValid = !!validateEmail(checkout.userEmail || '') && !!checkout.userPhone && !!checkout.guestName && (!!checkout.hotel || !!checkout.offer?.isDirectReporting);

  return (
    <>
      <div className='checkout-container'>
        <h2 className='checkout-title'>{t('checkout.guest')}</h2>
        <div className='checkout-box-wrap checkout-box-details'>
          <form className='checkout-details-form'>
            {!checkout.offer?.isDirectReporting && (
              <SelectRS
                onChange={(value) => setFieldCheckoutHandler('hotel', value.hotel)}
                placeholder={t('checkout.selectHotel')}
                options={filteredHotel}
                onInputChange={setFilterSelect}
                error={!isFormValid.hotel}
              />
            )}
            <div className='form-row'>
              <div className={!checkout.offer?.isDirectReporting ? 'form-col' : 'form-col-full'}>
                <Input
                  type='text'
                  onChange={(event) => setFieldCheckoutHandler('guestName', event.target.value)}
                  value={checkout.guestName}
                  placeholder={t('checkout.enterYourName')}
                  error={!isFormValid.guestName}
                />
              </div>
              {!checkout.offer?.isDirectReporting && (
                <div className='form-col'>
                  <Input
                    type='text'
                    onChange={(event) => setFieldCheckoutHandler('userRoomNumber', event.target.value)}
                    value={checkout.userRoomNumber}
                    placeholder={t('checkout.enterYourRoom')}
                  />
                </div>
              )}
            </div>
            <div className='form-row'>
              <div className='form-col'>
                <Input
                  type='email'
                  onChange={(event) => setFieldCheckoutHandler('userEmail', event.target.value)}
                  value={checkout.userEmail}
                  placeholder={t('checkout.enterYourEmail')}
                  error={!isFormValid.userEmail}
                />
              </div>
              <div className='form-col'>
                <PhoneInput
                  country={'ae'}
                  enableAreaCodes={true}
                  onChange={(event) => setFieldCheckoutHandler('userPhone', event)}
                  placeholder={t('checkout.selectMobile')}
                  value={checkout.userPhone}
                  inputProps={{
                    name: 'userPhone',
                    required: true
                  }}
                  isValid={(value, country) => {
                    // @ts-ignore
                    return isFormValid.userPhone || (value && value !== country.countryCode);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        <h2 className='checkout-title checkout-title_first'>{t('checkout.bookingSummary')}</h2>
        <div className='checkout-box-wrap checkout-box-summary'>
          <ul className='checkout-summary-list'>
            <li>
              <span className='checkout-summary-label'>{t('checkout.tourDate')}</span>
              {`${format(checkout.selectedDate || new Date(), 'dd.MM.yyyy')} (${
                checkout.timeSlot && timeFormat(checkout.timeSlot.startTime, checkout.timeSlot.endTime)
              })`}
            </li>
            {!checkout.offer?.isDirectReporting && checkout.hotel && (
              <li>
                <span className='checkout-summary-label'>{t('checkout.pickUpTime')}</span>
                {checkout.pickUpTime
                  ? `${format(addMinutes(emptyDate, checkout.pickUpTime.timeShiftStart), 'HH-mm')} - ${format(
                    addMinutes(emptyDate, checkout.pickUpTime.timeShiftEnd),
                    'HH-mm'
                  )}`
                  : t('checkout.selectHotelFirst')}
              </li>
            )}
            <li>
              <span className='checkout-summary-label'>{t('checkout.ticketType')}</span>
              {checkout.offer?.name}
            </li>
            <li>
              <span className='checkout-summary-label'>{t('checkout.numberOfPeopleLabel')}</span>

              {checkout.adultCount} {t('adults')} {checkout.childCount ? `/ ${checkout.childCount} ${t('child')}` : ''}{' '}
              {checkout.infantCount ? `/ ${checkout.infantCount} ${t('infant')}` : ''}
            </li>
            <li>
              <span className='checkout-summary-label'>{t('checkout.cancellationPolicyLabel')}</span>
              {checkout.cancellationPolicy?.map((item, index) =>
                `${index > 0 ? ' / ' : ''}${item.hours} ${declOfNum(item.hours, [t('checkout.clock1'), t('checkout.clock2'), t('checkout.clock3')])} - ${item.percents}%`
              )}
            </li>
            <li>
              <Link target={"_blank"} to={routes.legalInfoRoutes.paymentProcess}>Порядок проведения оплаты и возвратов по банковским картам</Link>
            </li>
          </ul>
        </div>

        <div className='checkout-box-wrap checkout-box-payment'>

          {stripePromise && (
            <Elements stripe={stripePromise}>
              <Payment
                request={bookingRequest}
                amount={amount}
                ticketType={checkout.offer?.name || ''}
                isValid={isValid}
              />
            </Elements>
          )}          
        </div>

        {/*<Accordion items={getFaq(t, checkout.faq, checkout.cancellationPolicy)} />*/}
      </div>
    </>
  );
};

export default Checkout;

