import { getAgencyGuid, getApiUrl } from '../../config/constants';
import { fetchRequest } from './index';
import { Booking, BookingRequest, PaymentIntent } from '../../types/booking';

const withPaymentIntent = async (
  request: BookingRequest
): Promise<{ booking: Booking; paymentIntent: PaymentIntent }> =>
  await fetchRequest.post(`${getApiUrl()}/bookings/withpaymentintent?AgencyGuid=${getAgencyGuid()}`, request);

const getBooking = async (bookingId: string, currency?: string): Promise<Booking> =>
  await fetchRequest.get(`${getApiUrl()}/bookings/${bookingId}?currencycode=${currency}`);

const bookings = {
  withPaymentIntent,
  getBooking
};
export default bookings;
