import React from 'react';
import { routes } from '../../config/constants';
import Ticket from '../ticket';
import Checkout from '../checkout';
import Confirm from '../confirm';
import { Route, Routes } from 'react-router-dom';
import PayPage from '../pay-page';

const EmbeddedLayout = () => {
  return (
    <>
      <div className="container">
        <Routes>
          <Route  path={routes.embeddedTicket} element={<Ticket />} />
          <Route  path={routes.embeddedCheckout} element={<Checkout />} />
          <Route  path={routes.confirm} element={<Confirm />} />
        </Routes>
      </div>
    </>
  );
};

export default EmbeddedLayout;
