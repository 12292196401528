import { Option } from 'react-dropdown';
import { IconChecked, IconLocation } from '../icons';
import DropdownRc from '../dropdown';
import { useTranslation } from 'react-i18next';
import * as React from 'react';

type Props = {
  onChange?: (arg: Option) => void;
  value?: Option
  cities?: Option[]
}

const Cities = ({ onChange, value, cities }: Props) => {
  const [t] = useTranslation();

  const c = cities?.reduce((acc: Option[], item) => {
    acc.push({...item, label: <><IconChecked />{item.label}</>})
    return acc;
  }, [])

  return c ? (
    <div className='select-city'>
    		<span>
       	<IconLocation /> City:
  		</span>
      <DropdownRc value={value} onChange={onChange} options={[{ label: t('citySelect.any'), value: '-1' } ,...c]} />
    </div>) : null;
};

export default Cities;