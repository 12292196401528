import { faPhone } from "@fortawesome/fontawesome-free-solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FooterDefault = () => {
    return (
        <footer id='main-footer'>
            <div className="footer-wrap">
                <div className="footer-column footer-legal-links">
                    <a href="tel:+971528996301"><FontAwesomeIcon icon={faPhone as IconProp} /> +971 52 899 63 01</a>
                </div>
                <div className="footer-column footer-logos">
                    <a href='https://api.whatsapp.com/send?phone=%2B971528996301'>
                        <FontAwesomeIcon size={"xs"} icon={faWhatsapp} />+971 52 899 63 01
                    </a> 
                </div>
                <div className="footer-column footer-contacts">               
                    <a target="_blank" href='mailto:web@absea.com' rel="noreferrer">
                        <FontAwesomeIcon icon={faEnvelopeOpen as IconProp} /> web@absea.ae
                    </a>           
                </div>
                <div className="footer-full">                
                    <span className='footer-copyright' style={{width:"100%", textAlign:"center"}}>© {(new Date()).getFullYear()} All Rights Reserved</span>
                </div>

            </div>     
        </footer>)
}
export default FooterDefault