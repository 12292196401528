import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import './i18n';

ReactDOM.render(

    <App />,
  document.getElementById('root')
);

const startInsp = () => {
  const w = window as any;
  w.__insp = w.__insp || [];
  w.__insp.push(['wid', 1324455360]);
  var ldinsp = function () {
    if (typeof w.__inspld != 'undefined') return;
    w.__inspld = 1;
    var insp = document.createElement('script');
    insp.type = 'text/javascript';
    insp.async = true;
    insp.id = 'inspsync';
    insp.src =
      ('https:' === document.location.protocol ? 'https' : 'http') +
      '://cdn.inspectlet.com/inspectlet.js?wid=1324455360&r=' +
      Math.floor(new Date().getTime() / 3600000);
    var x = document.getElementsByTagName('script')[0];
    x?.parentNode?.insertBefore(insp, x);
  };
  setTimeout(ldinsp, 0);
};

startInsp();
