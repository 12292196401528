import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import tourApi from '../../lib/api/tour';
import { TicketType } from '../../types/ticket';
import TourItem from './tour-item';
import { routes } from '../../config/constants';
import { IconSearch } from '../../components/icons';
import { getLanguage } from '../../config/constants'
import Input from '../../components/input';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CurrencyContext } from '../../contexts/currencyContext';

const Tour = () => {
  const [tours, setTours] = useState<TicketType[]>([]);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const [t] = useTranslation();
  const { currency } = useContext(CurrencyContext);


  const getTours = useCallback(async () => {
    const data = await tourApi.getTours(getLanguage(), currency)
    setTours(data);
  }, [currency])

  useEffect(() => {
    getTours();
  }, [getTours])

  const filteredTour = useMemo(() => tours?.filter((tour) => tour.name?.toLowerCase().includes(search)), [search, tours])

  return (
    <div className="content-wrap content-catalog-wrap">
      <div className="tour-search-bar-wrap tour-search-bar--catalog">
        <div className="tour-search-bar">
          <div className="search-block__item search-block__item--input">
            <Input placeholder={t("tour.search")} onChange={(e) => {
              setSearch(e.target.value.toLocaleLowerCase());}}/>
          </div>
          {/*<div className="search-block__item search-block__item--location">*/}
					{/*		<span>*/}
          {/*      								<IconLocation className="search-icon--location" />*/}
					{/*			City:*/}
					{/*		</span>*/}
          {/*  <SearchSelect className="input-wrap--select" value={city} onChange={setCity} options={cities}/>*/}
          {/*</div>*/}
          {/*<div className='search-block__item-divider'/>*/}
          {/*<div className="search-block__item search-block__item--language">*/}
					{/*		<span>*/}
          {/*      <IconLang className="search-icon--language" />*/}
					{/*			Language:*/}
					{/*		</span>*/}
          {/*  <SearchSelect className="input-wrap--select" value={language} onChange={setLanguage} options={languages}/>*/}
          {/*</div>*/}
          <div className="search-block__button-wrap">
            <button onClick={getTours} className="search-block--submit">
              <IconSearch />
            </button>
          </div>
        </div>
      </div>

      <div className="tickets-grid-wrap tickets-grid-4cols">
        {filteredTour.map(tour => (<TourItem key={tour.id} tour={tour} handleClick={() => navigate(`${routes.ticket}/${tour.url}`)}/>))}

      </div>
      <button className="tickets-load-more">{t("tour.loadMore")}</button>
    </div>
  )
}

export default Tour;

// const cities = [
//   {
//     value: 'aed',
//     label: 'Dubai'
//   }
// ]

// const languages = [
//   {
//     value: 'ru',
//     label: <div><img className="option-image" src={IconRu}  alt="ru"/>Русский</div>
//   }
// ]