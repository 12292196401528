const translation = {
  adult: 'Вз',
  adults: 'Взрослых',
  child: 'Рб',
  infant: 'Мл',
  bookNow: 'Забронировать',
  city: 'Доступен из ',
  tour: {
    from: 'От',
    loadMore: 'Загрузить еще',
    search: 'Поиск тура'
  },
  ticket: {
    select: 'Выберите билет',
    clear: 'Сбросить выбор',
    child: 'Рб',
    infant: 'Мл',
    perCar: 'Группа',
    selected: 'Выбранный билет',
    moreInfo: 'Подробнее',
    adultTooltip: '12 + лет',
    childTooltip: '5 - 11.99 лет',
    infantTooltip: '0 - 4.99 лет',
    pricePerVehicle: 'Цена за машину * <1> Максимум 6 человек</1>',
    time: 'Время',
    selectTime: 'Выберите время',
    total: 'Всего * <1>включая все налоги</1>',
    checkout: 'Перейти к оплате',
    selectDate: 'Чтобы продолжить, выберите дату',
    tourItinerary: 'Маршрут тура',
    terms: 'Условия',
    startingFrom: 'От',
    salesDescription: 'Описание',
    faq: 'Часто задаваемые вопросы',
    gallery: 'Галерея',
    video: 'Видео',
    notTicket: 'Нет доступных билетов'
  },
  checkout: {
    guest: 'Информация о госте',
    selectHotel: 'Выберите отель',
    enterYourName: 'Введите ваше имя',
    enterYourRoom: 'Укажите номер комнаты',
    enterYourEmail: 'Ваш Email',
    selectMobile: 'Номер телефона',
    bookingSummary: 'Информация о бронировании',
    tourDate: 'Дата',
    pickUpTime: 'Во сколько вас заберут',
    selectHotelFirst: 'Сначала необходимо выбрать отель',
    ticketType: 'Тип билета',
    numberOfPeopleLabel: 'Количество людей',
    cancellationPolicyLabel: 'Условия отмены',
    cancellationPolicy: '48 часов - 100% / 24 часа - 50%',
    clock1: 'час',
    clock2: 'часа',
    clock3: 'часов',
  },
  payment: {
    cardNumber: 'Номер карты',
    expirationDate: 'Срок действия карты',
    cvc: 'CVC',
    bookNow: 'Оплатить {{amount}} {{currency}}'
  },
  confirm: {
    title: 'Бронирование подтверждено',
    tourName: 'Название тура',
    totalCost: 'Общая стоимость',
    guestName: 'Имя гостя',
    hotelName: 'Имя отеля',
    roomNumber: 'Номер комнаты',
    mobileNumber: 'Номер телефона',
    email: 'Электронная почта',
    ticketInfo: 'Копия ваучера была отправлена на указанный адрес электронной почты.',
    downloadVoucher: 'Скачать ваучер',
    downloadTickets: 'Скачать билеты',
    downloadInvoice: 'Скачать счет',
    information: 'В случае, если указанная информация неверна или у вас есть дополнительные вопросы, свяжитесь с нами свяжитесь с нами по указанным ниже контактам.',
    reservationTeamContacts: 'Контакты отдела бронирования',
    questions: 'Вы можете связаться с нами по любым вопросам'
  },
  faq: {
    title1: 'Могу ли оплатить наличными на месте вместо карты?',
    body1: 'Бронирование осуществляется по предоплате, за исключением особых случаев. Вы можете связаться с нами, кликнув по иконке контактов в шапке. ',
    title2: 'Могу ли я вернуть деньги в случае отмены?',
    body2: 'Да, если вы хотите отменить бронирование, пожалуйста, свяжитесь с нами.\n {{cancellationPolicy}} Возврат денежных средств осуществляется за вычетом комиссии за проведение платежа, которая зависит от банка и платежной системы и составляет 2-3% от суммы. \n',
    cancellationPolicy48: 'В случае отмены бронирования за более чем 48 часов возвращается {{percent}}% стоимости. \n',
    cancellationPolicy24: 'В случае отмены бронирования за более чем 24 часа но менее чем за 48 часов, возвращается {{percent}}% стоимости. \n',
    title3: 'Куда мне необходимо явиться?',
    body3: 'Если вы приобрели билет, который включает трансфер и отеля, тогда вас заберут из отеля в указанное время сбора.\nЕсли вы приобрели билет без трансфера, вам будет необходимо самостоятельно добраться до указанного места к 20.30. Подробная данные о местоположении будут указаны в ваучере, который вы получите после оплаты.',
    title4: 'Что включено в билет?',
    body4: 'Все билеты включают All tickets include a cruise as well as a full course dinner and soft drinks. If you have purchased a package which includes unlimited house drinks, then you will have unlimited drinks from our bar.\nIf not, you will be able to order drinks from the bar at an extra charge.',

  },
  citySelect: {
    any: 'Все'
  },
  title: 'Страница оплаты бронирования №',
  paymentPage:'Страница оплаты',
  bookingId:"Номер бронирования",
  date:"Дата",
  tourName: "Экскурсия",
  ticketName: "Билет",
  hotel:"Отель",
  guestName: "Имя гостя",
  contactNumber: "Телефон",
  roomNumber: "Номер комнаты",
  paxNumber:"Кол. людей",
  children:"Дети",
  infants:"Младенцы",
  foc:"FOC",
  costPerTrip:"Цена за поездку",
  cancellationPolicy:"Условия отмены",
  totalCostWithVat:"Итоговая стоимость",
  cost: "Цена",
  total: "Итого",
  email:"Адрес электронной почты",



}

export default translation;