import DayPicker, { CalendarContainer, ReactDatePickerProps, registerLocale } from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import { IconArrowLeft, IconArrowRight } from '../icons';
import { forwardRef, useRef } from 'react';
import * as React from 'react';
import { getLanguage } from '../../config/constants';
import { format } from 'date-fns';
import en from 'date-fns/locale/en-US';
import ru from "date-fns/locale/ru";



// @ts-ignore
if(getLanguage() === 'ru')
  registerLocale('ru', ru);

const range = (start: number, end: number) => Array.from(Array(Math.abs(end - start) + 1), (_, i) => start + i);

const years = range(getYear(new Date()), getYear(new Date()) + 2);
// @ts-ignore
const months = Array.from(Array(12).keys()).map(index => format(new Date().setMonth(index), 'LLLL', {locale: language === 'ru' ? ru : en}))

const DatePickerComponent = (props: ReactDatePickerProps & { render?: React.ReactNode | undefined }) => {
  const datePickerRef = useRef(null);

  const CustomInput = forwardRef((props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
    <span onClick={props.onClick} ref={ref}></span>
  ));

  const CustomContainer = (props: {
    className?: string | undefined;
    children?: React.ReactNode | undefined;
    showPopperArrow?: boolean | undefined;
    arrowProps?: { [propName: string]: any } | undefined;
  }) => {
    return (
      <CalendarContainer className={props.className}>
        <button
          className='react-datepicker__close-icon'
          onClick={() => {
            // @ts-ignore
            datePickerRef.current?.setOpen(false);
          }}
        ></button>
        <div style={{ position: 'relative' }}>{props.children}</div>
      </CalendarContainer>
    );
  };
  return (
    <DayPicker
      {...props}
      ref={datePickerRef}
      locale={getLanguage()}
      withPortal={true}
      // customInput={props.render || <CustomInput />}
      calendarContainer={CustomContainer}
      renderCustomHeader={({
                             date,
                             changeYear,
                             changeMonth,
                             decreaseMonth,
                             increaseMonth,
                             prevMonthButtonDisabled,
                             nextMonthButtonDisabled
                           }) => (
        <div className='custom-react-datepicker__header'>
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <IconArrowLeft />
          </button>
          <div>
            <select
              className='custom-react-datepicker__select'
              value={date.getFullYear()}
              onChange={({ target: { value } }) => changeYear(parseInt(value))}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              className='custom-react-datepicker__select'
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <IconArrowRight />
          </button>
        </div>
      )}
    />
  );
};

export default DatePickerComponent;
