import React, { useState } from 'react';
import { getCurrencyCode } from '../config/constants';

export const CurrencyContext = React.createContext({currency: getCurrencyCode(), setCurrency: (val: string) => {}});

const CurrencyProvider = ({ ...props }) => {
  const [currency, setCurrency] = useState(getCurrencyCode())

  return <CurrencyContext.Provider value={{ currency, setCurrency }}>{props.children}</CurrencyContext.Provider>;
};

export default CurrencyProvider;
