import { routes } from '../../config/constants';
import Layout from './layout';
import EmbeddedLayout from './embedded-layout';
import ScrollToTop from './scroll-to-top';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const MyRoutes = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path={routes.embeddedTicket} element={<EmbeddedLayout />} />
      <Route path={routes.main} element={<Layout />} />
      {/* <Route component={NotFound} /> */}
    </Routes>
    <ToastContainer
      position='top-right'
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </BrowserRouter>
);

export default MyRoutes;
