
// @ts-ignore
export const getStripePubKey = () =>  process.env.REACT_APP_STRIPE_PUB_KEY || window.REACT_APP_STRIPE_PUB_KEY;
// @ts-ignore
export const getApiUrl = () =>  `${process.env.REACT_APP_API_URL || window.REACT_APP_API_URL}/api`;
// @ts-ignore
export const getTourId = () =>  process.env.REACT_APP_TOUR_ID || window.tourId;
// @ts-ignore
export const getWidgetId = () =>  window.widgetId;
// @ts-ignore
export const getLanguage = () => process.env.REACT_APP_LANGUAGE || window.language;
// @ts-ignore
export const getCurrencyCode = () =>  process.env.REACT_APP_CURRENCY_CODE || window.currencyCode;
// @ts-ignore
export const getUrlPrefix = () => window.urlPrefix || "";
// @ts-ignore
export const getAssetsUrl = () => process.env.REACT_APP_ASSETS_URL || window.assetsUrl || "";
// @ts-ignore
export const getAgencyGuid = () =>  window.agencyGuid || process.env.REACT_APP_AGENCY_GUID ||  '';

export const modulbankCallBackUrl = `${process.env.REACT_APP_API_URL}/api/bookings/confirmru`;

export const modulbankId = process.env.REACT_APP_MODULBANK_ID;

export const modulbankSuccessUrl = process.env.REACT_APP_MODULBANK_SUCCESS_URL;
export const modulbankSuccessUrlB2b = process.env.REACT_APP_MODULBANK_SUCCESS_URL_B2B;
export const modulbankTest = process.env.REACT_APP_MODULBANK_TEST;





export const emptyDate = new Date(2020, 1, 1, 0, 0, 0);

export const routes = {
  main: `${getUrlPrefix()}*`,
  ticket: `${getUrlPrefix()}`,
  embeddedTicket: `${getUrlPrefix()}/embedded`,
  checkout: `${getUrlPrefix()}/checkout`,
  embeddedCheckout: `${getUrlPrefix()}/embedded/checkout`,
  confirm: `${getUrlPrefix()}/confirm`,
  payPage: `${getUrlPrefix()}/pay-page`,
  legalInfoRoutes : {
    oferta: `${getUrlPrefix()}/oferta`,
    companyDetails: `${getUrlPrefix()}/company-details`,
    returnPolicy:`${getUrlPrefix()}/return-policy`,
    paymentProcess: `${getUrlPrefix()}/payment-process`
  }
};

