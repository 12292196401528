const PaymentsProcess = () => {
    return <div className='legal-text-container'>
        <h1>Порядок проведения оплаты и возврата по банковским картам</h1>
        <h2>Проведение оплаты:</h2>
        <p>Получив доступ в Интернет-магазин, Покупатель осуществляет выбор товаров/услуг и оформляет заказ на покупку товаров/предоставление услуг (далее – заказ).</p>
        <p>После успешного оформления заказа покупатель выбирает способ оплаты заказа при помощи карты и переадресуется из Интернет-магазина в систему Интернет-платежей Модульбанка pay.modulbank.ru для осуществления ввода реквизитов карты.</p>
        <p>При этом, из Интернет-магазина в систему Интернет-платежей Банка передается информация о заказе, определяемая «Протоколом». Каждому оформляемому через Интернет-магазин заказу присваивается уникальный номер (код), являющийся идентификатором заказа (далее – номер заказа). Порядок его формирования определяется «Протоколом».</p>
        <p>При этом:</p>
        <ul>
            <li>при положительном результате авторизации операции (получении от эмитента карты кода авторизации) Покупатель получает извещение об успешной оплате заказа;</li>
            <li>при отрицательном результате авторизации операции (поступлении от эмитента карты отказа в проведении авторизации операции) Покупатель получает извещение о невозможности оплаты заказа с использованием данной карты.</li>
        </ul>
        <p>Информация о результатах авторизации также передается из Системы Интернет-платежей Банка в Интернет магазин Клиента.</p>
        
        <h2>Информация о мерах по обеспечению безопасности Операций с использованием Карт:</h2>
        <p>Перечень реквизитов карты, вводимых Покупателем в системе Интернет-платежей Банка при проведении платежей через Интернет, устанавливается Банком в соответствии с Правилами Платежных систем.</p>
        <p>После успешного ввода Покупателем в Системе Интернет-платежей Банка реквизитов карты, необходимых для осуществления платежа через Интернет, Банк обеспечивает проведение авторизации операции и предоставляет информацию о ее результатах.</p>
        <p>Вводимая покупателем персональная информация на сайте pay.modulbank.ru хранится исключительно в банковской системе. Сайт банка поддерживает 128-битное SSL-шифрование для большей безопасности. Любая информация, переданная на платёжную страницу банка, в безопасности и защищена специальными средствами.</p>
    
        <h2>Проведение возврата:</h2>
        <p>Возврат денежных средств Покупателю при возврате товаров/отказе от услуг обеспечивается Банком (в соответствии с Правилами Платежных систем). Возврат производится эмитенту карты, безналичным образом, по реквизитам карты, с использованием которой проводилась исходная операция.</p>
        <p>Вышеуказанные действия производятся Банком на основании информации об операциях возврата товаров/отказа от услуг, имеющейся в Информационных системах Банка и ПЦ.</p>
        <p>Порядок перечисления эмитентом карты денежных средств держателю карты определяется внутренними правилами эмитента и/или договором между эмитентом и держателем карты.</p>
        <p>В случае возникновения ошибки при оплате покупатель может обратиться в техническую поддержку Модульбанка по телефону <a href="tel:88004440550">8 800 444 0550</a> или на почту <a href="mailto:int.acquiring@modulbank.ru">int.acquiring@modulbank.ru</a>.</p>

    </div>
}
export default PaymentsProcess