import React from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { getLanguage, routes } from '../../config/constants';
import Ticket from '../ticket';
import Checkout from '../checkout';
import Confirm from '../confirm';
import Tour from '../tour';
import { Route, Routes } from 'react-router-dom';
import Oferta from '../legal/oferta';
import CompanyDetails from '../legal/companyDetails';
import ReturnPolicy from '../legal/returnPolicy';
import PaymentsProcess from '../legal/paymentsProcess';
import PayPage from '../pay-page';

const Layout = () => {
  const language = getLanguage()
  return (
    <>
      <Header />

      <div className="container main-container">
        <Routes>
          {<Route path={routes.main} element={<Tour />} />}
          <Route  path={routes.checkout} element={<Checkout />} />
          <Route  path={`${routes.confirm}/:bookingId`} element={<Confirm />} />
          <Route  path={`${routes.ticket}/:id`} element={<Ticket />} />
          <Route  path={`${routes.payPage}/:guid`} element={<PayPage />} />

          {language === 'ru' ? <React.Fragment>
            <Route  path={`${routes.legalInfoRoutes.oferta}`} element={<Oferta/>}/>
            <Route  path={`${routes.legalInfoRoutes.companyDetails}`} element={<CompanyDetails/>}/>
            <Route  path={`${routes.legalInfoRoutes.returnPolicy}`} element={<ReturnPolicy/>}/>
            <Route  path={`${routes.legalInfoRoutes.paymentProcess}`} element={<PaymentsProcess/>}/>
          </React.Fragment> : null}
        </Routes>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
