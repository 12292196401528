import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Booking } from '../types/booking';
import { paymentApi } from '../lib/api';
import PaymentModulbank from '../components/paymentModul';
import { modulbankId, modulbankSuccessUrlB2b, modulbankTest, routes } from '../config/constants';
import BookingSummary from '../components/booking-summary';


const PayPage = () => {
  const params = useParams();
  const [booking, setBooking] = useState<Booking>();
  const [searchParams] = useSearchParams()
  const successUrl = searchParams.get('successurl')
  useEffect(() => {
    const getBooking = async () => {
      const data = params.guid && (await paymentApi.getPayment(params.guid,   'rub'));
      data && setBooking(data);
    };
    getBooking();
  }, [params.guid]);

  return booking ? (
    <div className="container">
      <BookingSummary booking={booking} />
          <PaymentModulbank
            amount={booking.totalPayable}
            amountUsd={booking.totalPayableUsd}
            convRate={booking.convRate}
            booking={booking}
            isValid={true}
            ticketType={booking.offerName}
            modulbankId={modulbankId || ''}
            modulbankSuccessUrl={`${successUrl}` || `${modulbankSuccessUrlB2b}/${booking.id}` || ''}
            modulbankTest={modulbankTest}
            legalInfoPaymentProcessUrl={routes.legalInfoRoutes.paymentProcess}
          />
    </div>
  ) : null;
};

export default PayPage;
