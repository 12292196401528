import { SrcSetType } from '../../types/ticket';
import { useMemo } from 'react';

type ImgWithFallbackProps = {
  data?: SrcSetType[];
  alt: string;
  width?: number;
};

const ImgWithFallback = ({ data, alt, width = 320, ...props }: ImgWithFallbackProps) => {
  // const { width } = useViewport();
  const { source, fallback } = useMemo(
    () =>
      data?.reduce(
        (prev, item) => {
          if (item.type === 'image/webp') {
            // prev.source = item.sizes.reduce(
            //   (prevSize, size) => (size.width <= width && size.width > prevSize.width ? size : prevSize),
            //   { width: 0, src: '' }
            // ).src;
            prev.source = item.sizes.find((size) => size.width === width)?.src || ''; // Get S size always
          } else if (item.type === 'image/jpg') {
            // prev.fallback = item.sizes.reduce(
            //   (prevSize, size) => (size.width <= width && size.width > prevSize.width ? size : prevSize),
            //   { width: 0, src: '' }
            // ).src;
            prev.fallback = item.sizes.find((size) => size.width === width)?.src || ''; // Get S size always
          }
          return prev;
        },
        { source: '', fallback: '' }
      ) || { source: '', fallback: '' },
    [data, width]
  );
  return (
    <picture>
      <source srcSet={source} width='100%' type='image/webp' />
      <img src={fallback} width='100%' alt={alt} {...props} />
    </picture>
  );
};

export default ImgWithFallback;
