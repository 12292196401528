import { fetchRequest } from './index';
import { Payment, RuPaymentDetailsPreset } from '../../types/payment';
import { getApiUrl } from '../../config/constants';
import { Booking } from '../../types/booking';


const getPayment = async (bookingId: string, currency?: string): Promise<Booking> =>
  await fetchRequest.get(`${getApiUrl()}/bookings/payment/${bookingId}?currencycode=${currency}`);
const getRuPaymentSignature = async (values: {fieldName: string, value: string}[]): Promise<{sbp: string, card: string}> =>
  await fetchRequest.post(`${getApiUrl()}/payments/signature`, {values});
const setRuPaymentDetails = async(details: RuPaymentDetailsPreset):Promise<boolean> => 
  await fetchRequest.post(`${getApiUrl()}/payments/detailsru`, details);
const payment = {
  getPayment, getRuPaymentSignature, setRuPaymentDetails
};
export default payment;
