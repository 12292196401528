import { useMemo } from 'react';
import isEqual from 'date-fns/isEqual';
import { startOfDay } from 'date-fns';
import addMinutes from 'date-fns/addMinutes';
import { PriceType, TimeSlotType } from '../../types/ticket';
import { Availability } from '../../types/availability';

const useCarousel = (availabilities: Availability[], utcOffset: number, timeSlot?: TimeSlotType, prices?: PriceType[]) => {
  const carouselItems = useMemo(() => {
    return (
      prices?.reduce((acc: { date: Date; isEnable: boolean }[], item) => {
        const date = new Date(item.date);
        const availability = availabilities.find(
          (availabilityItem) =>
            timeSlot?.id === availabilityItem.timeSlotId &&
            isEqual(startOfDay(date), startOfDay(new Date(availabilityItem.date)))
        );
        let isMinimumAdvancedBooking = true;
        if (timeSlot) {
          const localDate = new Date(date.valueOf() + utcOffset * 60 * 60 * 1000);
          isMinimumAdvancedBooking = new Date((new Date()).valueOf() + utcOffset * 60 * 60 * 1000 + date.getTimezoneOffset() * 60 * 1000) < addMinutes(localDate, timeSlot.startTime - (timeSlot.minimumAdvancedBooking * 60));
        }
        acc.push({ date: date, isEnable: !!availability && availability.left > 0 && isMinimumAdvancedBooking });
        return acc;
      }, []) || []
    );
  }, [timeSlot, prices, availabilities, utcOffset]);

  return {
    carouselItems
  };
};

export default useCarousel;