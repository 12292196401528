import { TicketType } from '../../types/ticket';
import ImgWithFallback from '../../components/ImgWithFallback';
import { IconArrowRight } from '../../components/icons';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyContext } from '../../contexts/currencyContext';
import { limitStr } from '../../lib/utils';
import useViewport from '../../hooks/use-viewport';


type TourProps = {
  tour: TicketType
  handleClick: () => void
}

const TourItem = ({tour, handleClick} : TourProps) => {
  const [t] = useTranslation();
  const { currency } = useContext(CurrencyContext);
  const { width } = useViewport();
  const limit = width > 1200 ? 50 :
    width > 960 ? 35 :
      width > 760 ? 90 : 100

  return (
    <div onClick={handleClick} className="tickets-grid-item">
    <div className="tickets-grid-item--image">
      {tour.heroImage?.srcSet && <ImgWithFallback
        data={tour.heroImage?.srcSet }
        alt={tour.heroImage?.caption }
      />}
    </div>
    <div className="tickets-grid-item--content">
      <h4>{limitStr(tour.name, limit)}</h4>
      <div className="tickets-grid-item--info">
        <span>{t("tour.from")}</span>{tour.price.toFixed(0)} {currency}
        <div className="arrow-right-icon"><IconArrowRight /></div>
      </div>
    </div>
  </div>)
}

export default TourItem;