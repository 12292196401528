import { fetchRequest } from './index';
import { getAgencyGuid, getApiUrl } from '../../config/constants';
import { PickUpTime, PriceType, TicketType } from '../../types/ticket';
import { Hotel } from '../../types/hotel';
import format from 'date-fns/format';
import { addDays } from 'date-fns';

const getTickets = async (id: string, currency: string): Promise<TicketType> => {
  const ticket = await fetchRequest.get(`${getApiUrl()}/widgets/tour/url/${id}`, { currencyCode: currency, agencyGuid: getAgencyGuid() });
  if (ticket) return ticket;
  throw new Error('Не удалось получить данные');
};

const getHotels = async (countryId: number): Promise<Hotel[]> =>
  await fetchRequest.get(`${getApiUrl()}/hotels/listbase`, { countryId: countryId });

const getOffersPrices = async (offerId: number, from: Date, currency: string): Promise<PriceType[]> =>
  await fetchRequest.get(`${getApiUrl()}/widgets/offersPrices`, {
    ids: offerId,
    from: format(addDays(from, -7), 'yyyy-MM-dd'),
    to: format(addDays(from, 7), 'yyyy-MM-dd'),
    currencyCode: currency,
    AgencyGuid: getAgencyGuid()
  });

const getPickUp = async (pickUpTimeId?: number, hotelId?: number, startTime?: number): Promise<PickUpTime> =>
  await fetchRequest.get(`${getApiUrl()}/pickuptimes/value`, {
    pickUpTimeId: pickUpTimeId,
    hotelId: hotelId,
    startTime: startTime
  });

const ticket = {
  getTickets,
  getHotels,
  getPickUp,
  getOffersPrices
};

export default ticket;
