import React from 'react';
import { useTranslation } from 'react-i18next';
import useViewport from '../../hooks/use-viewport';

type Props = {
  videoUrl: string;
}

const Video = ({videoUrl}: Props) => {
  const [t] = useTranslation();
  let url;

  const { width } = useViewport();
  let videoWidth = 0;

  if(width > 1200) {
    videoWidth = 680
  }
  else if (width > 960){
    videoWidth = width - 387
  }
  else {
    videoWidth = width - 120
  }
  const height = videoWidth / 1.7

  if(videoUrl.match(/youtu/)){
    const arr = videoUrl.split('/')
    let id = arr[arr.length - 1];
    if(id.match(/v=/)){
      id = id.split('v=')[1];
    }
    url = `https://www.youtube.com/embed/${id}`
  }
  if(videoUrl.match(/vimeo/)){
    const arr = videoUrl.split('/')
    const id = arr[arr.length - 1];
    url = `https://player.vimeo.com/video/${id}`
  }

  return <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '16px'}}><iframe width={videoWidth} height={height} title={t('ticket.video')}  src={url} frameBorder="0" /></div>;
}

export default Video;