import React from 'react';
import MaskedInput from 'react-text-mask';

export type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  mask?: Array<RegExp | string>;
  error?: boolean;
};

const Input = (props: Props) =>
  props.mask ? (
    <MaskedInput
      mask={props.mask}
      placeholderChar={'\u2000'}
      placeholder={props.placeholder}
      type={props.type}
      guide={false}
      render={(ref, renderProps) => (
        <input
          {...renderProps}
          // @ts-ignore
          ref={ref}
          className={'custom-input ' + (props.error ? 'input-error' : '')}
        />
      )}
    />
  ) : (
    <input {...props} className={'custom-input ' + (props.error ? 'input-error' : '')} />
  );

export default Input;
