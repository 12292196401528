import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from '../locale/en'
import translationRu from '../locale/ru'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEn
      },
      ru: {
        translation: translationRu
      },
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;