import { fetchRequest } from './index';
import { getApiUrl } from '../../config/constants';
import { City } from '../../types/city';

const getCities = async (countryId: number, language: string,): Promise<City[]> =>
  await fetchRequest.get(`${getApiUrl()}/cities/options/${countryId}`, { language });

const cities = {
  getCities
};

export default cities;