import { fetchRequest } from './index';
import { getAgencyGuid, getApiUrl } from '../../config/constants';
import { TicketType } from '../../types/ticket';

const getTours = async (language: string, currency: string): Promise<TicketType[]> => {
  const ticket = await fetchRequest.get(`${getApiUrl()}/widgets/tours`, { currencyCode: currency, language, includeOffers: false, agencyGuid: getAgencyGuid()});
  if (ticket) return ticket;
  throw new Error('Не удалось получить данные');
};

const tourApi = {
  getTours,
};

export default tourApi;
