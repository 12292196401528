import Dropdown, { Option } from 'react-dropdown';

type Props = {
  options: Option[]
  onChange?: (arg: Option) => void;
  value?: Option
}

const DropdownRc = ({options, onChange, value}: Props) => <Dropdown
  options={options}
  value={value}
  onChange={onChange}
  arrowClosed={<svg xmlns="http://www.w3.org/2000/svg" width="11.289" height="6.435" viewBox="0 0 11.289 6.435">
    <path id="Path_23" data-name="Path 23" d="M6.2,6.2,1.349,11.057A.79.79,0,0,1,.231,9.939L4.526,5.644.232,1.35A.791.791,0,0,1,1.35.232L6.2,5.085A.79.79,0,0,1,6.2,6.2Z" transform="translate(11.289) rotate(90)"/>
    </svg>}
  arrowOpen={<svg xmlns="http://www.w3.org/2000/svg" width="11.289" height="6.435" viewBox="0 0 11.289 6.435">
    <path id="Path_23" data-name="Path 23" d="M6.2,6.2,1.349,11.057A.79.79,0,0,1,.231,9.939L4.526,5.644.232,1.35A.791.791,0,0,1,1.35.232L6.2,5.085A.79.79,0,0,1,6.2,6.2Z" transform="translate(11.289) rotate(90)"/>
  </svg>}
/>;

export default DropdownRc;