import {Booking} from "../../types/booking";
import format from 'date-fns/format';
import { useTranslation } from "react-i18next";
import { round } from '../../lib/utils';


type BookingSummaryProps = {
    booking: Booking
}

const BookingSummary = ({booking}: BookingSummaryProps) => {

    const [t] = useTranslation();  
    return <>
        <h2 className="title">{t('bookingId')} {booking.id}, {t('date')} {format(new Date(booking.date), 'dd.MM.yyyy')}</h2>

        <div className="summary-container">
        <table className="summary-table">
            <tbody>
            {booking.tourName ? (<tr>
                    <td>{t('tourName')}</td>
                    <td>{booking.tourName}</td>
                </tr>) : null}
                <tr>
                    <td>{t('ticketName')}</td>
                    <td>{booking.offerName}</td>
                </tr>
                <tr>
                    <td>{t('hotel')}</td>
                    <td>{booking.hotelName}</td>
                </tr>
                <tr>
                    <td>{t('guestName')}</td>
                    <td>{booking.guestName}</td>
                </tr>
                <tr>
                    <td>{t('contactNumber')}</td>
                    <td>{booking.phone}</td>
                </tr>
                {booking.email && <tr>
                    <td>{t('email')}</td>
                    <td>{booking.email}</td>
                </tr>}
                <tr>
                    <td>{t('roomNumber')}</td>
                    <td>{booking.roomNumber}</td>
                </tr>
            </tbody>
        </table>

            {(!booking.isSupplierVoucher) && (<table className="summary-table">
            <thead>
            <tr>
                <th></th>
                <th>{t('paxNumber')}</th>
                <th>{t('cost')}</th>
                <th>{t('total')}</th>
            </tr>
            </thead>
            <tbody>
            {booking.numAdults ?
                (<tr>
                    <td>{t('adults')}</td>
                    <td>{booking.numAdults}</td>
                    <td>{booking.pricePerAdult}</td>
                    <td>{booking.numAdults * booking.pricePerAdult}</td>
                </tr>) : null}
                {booking.numChildren ?
                    (<tr>
                    <td>{t('children')}</td>
                    <td>{booking.numChildren}</td>
                    <td>{booking.pricePerChild}</td>
                    <td>{booking.numChildren * booking.pricePerChild}</td>
                </tr>) : null}
                {booking.numInfants ?
                    (<tr>
                    <td>{t('infants')}</td>
                    <td>{booking.numInfants}</td>
                    <td>0</td>
                    <td>0</td>
                </tr>) : null}
                {booking.numFoc ?
                (<tr>
                    <td>FOC</td>
                    <td>{booking.numFoc}</td>
                    <td>0</td>
                    <td>0</td>
                </tr>) : null}
            {booking.pricePerTrip ?
                (<tr>
                    <td>{t('costPerTrip')}</td>
                    <td>n/a</td>
                    <td>{booking.pricePerTrip}</td>
                    <td>{booking.pricePerTrip}</td>
                </tr>) : null}
            </tbody>
        </table>)}
            <table className="summary-table">
                <tbody>

                            <tr>
                                <td>{t('totalCostWithVat')}, USD</td>
                                <td>{booking.totalPayableUsd} $</td>
                            </tr>
                            <tr>
                                <td>{t('totalCostWithVat')} AED</td>
                                <td>{round(booking.totalPayableUsd * 3.65)} AED</td>
                            </tr>
                            <tr>
                                <td>{t('totalCostWithVat')}, RUB</td>
                                <td>{booking.totalPayableBeforeAdditionalCommissions} руб.</td>
                            </tr>
                            <tr>
                                <td>Налог, RUB (6%)</td>
                                <td>{booking.additionalTaxes} руб.</td>
                            </tr>
                            <tr>
                                <td>Комиссия, RUB (4%)</td>
                                <td>{booking.additionalPaymentProcessingFee} руб.</td>
                            </tr>
                            <tr>
                                <td><strong>Итоговая сумма к оплате, RUB</strong></td>
                                <td><strong>{booking.totalPayable} руб.</strong></td>
                            </tr>

                </tbody>
            </table>
                <table className="summary-table">
                    <tbody>
                <tr>
                    <td>{t('cancellationPolicy')}</td>
                    <td>{booking.cancellationPolicy?.map((item, index) => (<span>{index > 0 ? "/" : ""} {item.hours}-hours - {item.percents}% </span>))}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </>
};


export default BookingSummary;