import React, { useEffect, useState } from 'react';
import AliceCarousel, { EventObject } from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

type Props = {
  items: JSX.Element[]
}

const thumbItems = (items: JSX.Element[], [setThumbIndex, setThumbAnimation]: [setThumbIndex: (i: number) => void, setThumbAnimation: (flag: boolean) => void]) => {
  return items.map((item, i) => (
    <div className="thumb" onClick={() => {
      setThumbIndex(i);
      setThumbAnimation(true)}}>
      {item}
    </div>
  ));
};

const Carousel = ({items}: Props) => {
  const [mainIndex, setMainIndex] = useState(0);
  const [mainAnimation, setMainAnimation] = useState(false);
  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);
  const [thumbs, setThumbs] = useState(thumbItems(items, [setThumbIndex, setThumbAnimation]));

  useEffect(() => setThumbs(thumbItems(items, [setThumbIndex, setThumbAnimation])), [items])

  const syncMainBeforeChange = () => {
    setMainAnimation(true);
  };

  const syncMainAfterChange = (e: EventObject) => {
    setMainAnimation(false);

    if (e.type === 'action') {
      setThumbIndex(e.item);
      setThumbAnimation(false);
    } else {
      setMainIndex(thumbIndex);
    }
  };

  const syncThumbs = (e: EventObject) => {
    setThumbIndex(e.item);
    setThumbAnimation(false);

    if (!mainAnimation) {
      setMainIndex(e.item);
    }
  };

  return (
    <>
    <AliceCarousel
      activeIndex={mainIndex}
      animationType="fadeout"
      disableDotsControls
      infinite
      items={items}
      mouseTracking={!thumbAnimation}
      onSlideChange={syncMainBeforeChange}
      onSlideChanged={syncMainAfterChange}
      touchTracking={!thumbAnimation}
    />
    <div className="thumbs">
      <AliceCarousel
        activeIndex={thumbIndex}
        autoWidth
        disableDotsControls
        disableButtonsControls
        items={thumbs}
        mouseTracking={false}
        onSlideChanged={syncThumbs}
        touchTracking={!mainAnimation}
        infinite
      />
    </div>
    </>)
};

export default Carousel;