import { fetchRequest } from './index';
import { getApiUrl } from '../../config/constants';
import { Availability } from '../../types/availability';
import { addYears, format } from 'date-fns';

const getByOfferId = async (offerId: number, from: Date): Promise<Availability[]> => {
  const dateFrom = from <= addYears(new Date(), 1) ? new Date() : from;
  return await fetchRequest.get(
    `${getApiUrl()}/availability/byoffer?from=${format(dateFrom, 'yyyy-MM-dd')}&to=${format(
      addYears(dateFrom, 1),
      'yyyy-MM-dd'
    )}&id=${offerId}`
  );
}
const getByTimeSlots = async (timeSlotId: number[] | undefined, from: Date): Promise<Availability[]> => {
  const dateFrom = from <= addYears(new Date(), 1) ? new Date() : from;
  return await fetchRequest.get(
    `${getApiUrl()}/availability/bytimeslots?from=${format(dateFrom, 'yyyy-MM-dd')}&to=${format(
      addYears(dateFrom, 1),
      'yyyy-MM-dd'
    )}${timeSlotId?.reduce((prev, cur) => `${prev}&ids=${cur}`, '')}`
  );
};

const availabilityApi = {
  getByTimeSlots, getByOfferId
};

export default availabilityApi;
