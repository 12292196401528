import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import { emptyDate } from '../config/constants';

export const timeFormat = (startTime: number, endTime: number) => `${format(addMinutes(emptyDate, startTime), 'HH-mm')} - ${format(
  addMinutes(emptyDate, endTime),
  'HH-mm'
)}`;

export const declOfNum = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}

export const  limitStr = (str: string, n: number, symb?: string) => {
  if (!n && !symb) return str;
  symb = symb || '...';
  return str.length > n ? (str.substr(0, n - symb.length) + symb) : str;
}

export const round = (value: number, decimals: number = 2) => {
  if(typeof(value) == 'undefined') return value
  const strVal = value.toString()
  if(strVal.indexOf('.') === -1 || strVal.split('.')[1].length <= decimals){
    return value
  }
  const rounder = Math.pow(10,decimals);
  return Math.round(value*rounder)/rounder;
}