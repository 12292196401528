import { ReactNode } from 'react';

export type ButtonProps = {
  color?: 'blue' | 'dark-blue' | 'orange' | 'green' | 'gray';
  size?: 'small' | 'medium' | 'large';
  label?: string;
  disabled?: boolean;
  load?: boolean;
  iconRight?: () => ReactNode;
  iconLeft?: () => ReactNode;
  active?: boolean;
  onClickHandler?: () => void;
  submit?: boolean;
  fullWidth?: boolean;
  customClassName?: string;
};

const Button: React.FC<ButtonProps> = (props) => {
  let buttonClass = 'button';
  buttonClass = props.fullWidth ? `${buttonClass} button_fullWidth` : buttonClass;
  switch (props.color) {
    case 'blue':
      buttonClass = `${buttonClass} button_blue`;
      break;
    case 'dark-blue':
      buttonClass = `${buttonClass} button_dark-blue`;
      break;
    case 'orange':
      buttonClass = `${buttonClass} button_orange`;
      break;
    case 'green':
      buttonClass = `${buttonClass} button_green`;
      break;
    case 'gray':
      buttonClass = `${buttonClass} button_gray`;
      break;
  }
  switch (props.size) {
    case 'small':
      buttonClass = `${buttonClass} button_small`;
      break;
    case 'medium':
      buttonClass = `${buttonClass} button_medium`;
      break;
    case 'large':
      buttonClass = `${buttonClass} button_large`;
      break;
  }

  if (props.customClassName) buttonClass = `${buttonClass} ${props.customClassName}`;

  return (
    <button
      type={props.submit ? 'submit' : 'button'}
      disabled={props.disabled || props.load}
      data-active={props.active ? props.active : undefined}
      className={buttonClass}
      onClick={props.onClickHandler}
    >
      {/*{props.load && <Loader {...props} />}*/}
      <span {...props} data-one-icon={!props.label}>
        {!props.load && (
          <>
            {props.iconLeft ? <i {...props}>{props.iconLeft()}</i> : null}
            {props.label}
            {props.iconRight && <i {...props}>{props.iconRight()}</i>}
          </>
        )}
      </span>
    </button>
  );
};

export default Button;
