import Button from '../../components/button';
import { IconTicketButton } from '../../components/icons';
import NumberInput from '../../components/number-input';
import React, { useCallback, useContext, useMemo } from 'react';
import SelectRS from '../../components/select-rs';
import { HeroImageType, OfferType } from '../../types/ticket';
import ImgWithFallback from '../../components/ImgWithFallback';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import { emptyDate } from '../../config/constants';
import { CheckoutData } from '../checkout';
import { Availability } from '../../types/availability';
import Carousel from '../../components/carousel';
import useCarousel from '../../components/carousel/useCarousel';
import { timeFormat } from '../../lib/utils';
import { Trans, useTranslation } from 'react-i18next';
import useViewport from '../../hooks/use-viewport';
import { CurrencyContext } from '../../contexts/currencyContext';


type TicketProps = {
  offer: OfferType;
  heroImage: HeroImageType;
  isSelected: boolean;
  selectHandler: (value: number) => void;
  editOfferObj?: CheckoutData;
  handleCheckoutBtn: () => void;
  handleChangeField: (value: any, name: string) => void;
  availabilities: Availability[];
  loadMoreDates: () => void;
  utcOffset: number;
  cities: React.ReactNode[] | undefined
};

const Offer = ({
                 editOfferObj,
                 offer,
                 isSelected,
                 selectHandler,
                 heroImage,
                 handleCheckoutBtn,
                 handleChangeField,
                 availabilities,
                 loadMoreDates,
                 utcOffset,
                 cities
               }: TicketProps) => {

  const [t] = useTranslation();
  const { currency } = useContext(CurrencyContext);

  const { priceFromAdult, priceFromChild, pricePerTrip } = useMemo(
    () =>
      offer.prices.reduce(
        (prev, cur) => {
          prev.priceFromAdult =
            prev.priceFromAdult === 0 || cur.pricePerAdult < prev.priceFromAdult
              ? cur.pricePerAdult
              : prev.priceFromAdult;
          prev.priceFromChild =
            prev.priceFromChild === 0 || cur.pricePerChild < prev.priceFromChild
              ? cur.pricePerChild
              : prev.priceFromChild;
          prev.pricePerTrip =
            prev.pricePerTrip === 0 || cur.pricePerTrip < prev.pricePerTrip ? cur.pricePerTrip : prev.pricePerTrip;
          return prev;
        },
        { priceFromAdult: 0, priceFromChild: 0, pricePerTrip: 0 }
      ),
    [offer]
  );


  const timeOptions = useMemo(
    () =>
      offer.timeSlots
        .filter((item) => availabilities.some((a) => a.timeSlotId === item.id && a.left > 0))
        .map((timeSlot) => ({
          value: timeSlot.id,
          label: timeFormat(timeSlot.startTime, timeSlot.endTime)
        })),
    [offer, availabilities]
  );

  const total = useMemo(
    () => {
      const price = (editOfferObj && editOfferObj.selectedPrice) || (offer.prices.length && offer.prices[0]);
      return editOfferObj && price
        ? (
          editOfferObj.adultCount * price.pricePerAdult +
          editOfferObj.childCount * price.pricePerChild +
          editOfferObj.infantCount * (price.pricePerInfant || 0) +
          price.pricePerTrip
        ).toFixed(0)
        : 0;
    },
    [editOfferObj, offer.prices]
  );

  const excludeDates = useMemo(
    () =>
      availabilities.reduce((prev: Date[], cur) => {
        if (editOfferObj?.timeSlot?.id === cur.timeSlotId && cur.left < 1) {
          prev.push(new Date(cur.date));
        }
        return prev;
      }, []),
    [availabilities, editOfferObj?.timeSlot]
  );

  const { carouselItems } = useCarousel(availabilities, utcOffset, editOfferObj?.timeSlot, editOfferObj?.offer?.prices);

  const handleChangePeopleCount = useCallback(
    (value: number, field: 'adultCount' | 'childCount' | 'infantCount') => {
      if (editOfferObj) {
        const totalPeople = editOfferObj.adultCount + editOfferObj.childCount + editOfferObj.infantCount;
        if (totalPeople >= offer.maxNumOfPeople && editOfferObj[field] < value) {
          console.log('Error: max people');
          return;
        }

        if (
          editOfferObj.availability &&
          totalPeople >= editOfferObj.availability?.left &&
          editOfferObj[field] < value
        ) {
          console.log('Error: max left availability');
          return;
        }
        handleChangeField(value, field);
      }
    },
    [editOfferObj, handleChangeField, offer.maxNumOfPeople]
  );

  const oneInfoBlock = !editOfferObj?.offer?.description;
  const { width } = useViewport();

  return (
    <div className='ticket-item'>
      <div className='ticket-item--preview' onClick={() => selectHandler(offer.id)}>
        <div className='ticket-item__image'>
          <ImgWithFallback
            data={offer.heroImage?.srcSet || heroImage?.srcSet}
            alt={offer.heroImage?.caption || heroImage?.caption}
            width={320}
          />
        </div>
        <div className='ticket-item__title ticket-item-container'>
          <h3>{offer.name}</h3>
          <span>{t('city')} {cities?.join(', ')}</span>
        </div>
        <div className="ticket-item__price ticket-item-container">
          <div className="ticket-item__price-wrap">
            {!!priceFromAdult  ? <div className="ticket-item__price-item">
              <div className="ticket-item__price-sum">
                {priceFromAdult.toFixed(0)}
                <span>&nbsp;{currency}</span>
              </div>
              <span>{t('adult')}</span>
            </div> : null}
            {!!priceFromChild  ? <div className="ticket-item__price-item">
              <div className="ticket-item__price-sum">
                {priceFromChild.toFixed(0)}
                <span>&nbsp;{currency}</span>
              </div>
              <span>{t('ticket.child')}</span>
            </div> : null}
            {!!pricePerTrip ? (
              <div className="ticket-item__price-item">
                <div className="ticket-item__price-sum">
                  {pricePerTrip.toFixed(0)}
                  <span>&nbsp;{currency}</span>
                </div>
                <span>{t('ticket.perCar')}</span>
              </div>
            ) : null}
          </div>
        </div>
        <div className='ticket-item__button'>
          <Button
            active={isSelected}
            iconLeft={() => <IconTicketButton />}
            label={isSelected ? t('ticket.selected') : t('ticket.moreInfo')}
          />
        </div>
      </div>

      <div className={`ticket-item--detail-wrap ${isSelected ? 'ticket-item_open' : 'ticket-item_close'}`}>
        <div className='ticket-item--detail'>
          {!oneInfoBlock &&
          <div className='ticket-item--detail--left'>
            <div
              className='ticket-item__description'
              dangerouslySetInnerHTML={{ __html: editOfferObj?.offer?.description || '' }}
            ></div>
          </div>
          }
          <div className={`ticket-item--detail--right ${oneInfoBlock ? 'ticket-item--detail--one' : ''}`}>
            <NumberInput
              label={t('adult')}
              tooltip={t('ticket.adultTooltip')}
              price={editOfferObj?.selectedPrice?.pricePerAdult || priceFromAdult}
              count={editOfferObj?.adultCount}
              onChange={(value) => handleChangePeopleCount(value, 'adultCount')}
              minValue={1}
            />
            {offer.childrenAllowed && (
              <NumberInput
                label={t('child')}
                tooltip={t('ticket.childTooltip')}
                price={editOfferObj?.selectedPrice?.pricePerChild || priceFromChild}
                count={editOfferObj?.childCount}
                onChange={(value) => handleChangePeopleCount(value, 'childCount')}
                minValue={0}
              />
            )}
            {offer.infantsAllowed && (
              <NumberInput
                label={t('infant')}
                tooltip={t('ticket.infantTooltip')}
                price={editOfferObj?.selectedPrice?.pricePerInfant || 0}
                count={editOfferObj?.infantCount}
                onChange={(value) => handleChangePeopleCount(value, 'infantCount')}
                minValue={0}
              />
            )}
            {editOfferObj?.selectedPrice && editOfferObj.selectedPrice.pricePerTrip > 0 && (
              <div className='ticket-item-per-trip'>
                <div className='ticket-item-per-trip__label'>
                  <Trans i18nKey={`ticket.pricePerVehicle`}>
                    Price per Vehicle * <span> Max 6 people</span>
                  </Trans>
                </div>
                <div
                  className='ticket-item-summary__summ'>{editOfferObj.selectedPrice.pricePerTrip || pricePerTrip} {currency}
                </div>
              </div>
            )}
            <div className='ticket-item-time'>
              <div className='ticket-item-time__label'>
                {timeOptions.length === 1 ? (
                  <>
                    <div>{t('ticket.time')}</div>
                    <div className='ticket-item-time__label_right'>{`${format(
                      addMinutes(emptyDate, editOfferObj?.timeSlot?.startTime || 0),
                      'HH-mm'
                    )} - ${format(addMinutes(emptyDate, editOfferObj?.timeSlot?.endTime || 0), 'HH-mm')}`}</div>
                  </>
                ) : (
                  <>{t('ticket.selectTime')}:</>
                )}
              </div>
              {timeOptions.length > 1 && (
                <div className='ticket-select-time__input'>
                  <SelectRS
                    isNotSearchable={true}
                    onChange={(o) => {
                      handleChangeField(
                        offer.timeSlots.find((slot) => slot.id === o.value),
                        'timeSlot'
                      );
                    }}
                    options={timeOptions}
                    disabled={timeOptions.length === 1}
                  />
                </div>
              )}
            </div>
            <Carousel
              carouselItems={carouselItems}
              selectedDate={editOfferObj?.selectedDate}
              onChange={(value) => handleChangeField(value, 'selectedDate')}
              excludeDates={excludeDates}
              handleNextClick={loadMoreDates}
              visibleSlides={oneInfoBlock ? width > 760 ? 6 : width > 640 ? 5 : width > 500 ? 4 : 3 : width > 640 && width < 760 ? 5 : width > 500 && width < 760 ? 4 : 3}
            />

            <div className='ticket-item-summary'>
              <div className='ticket-item-summary__label'>
                <Trans i18nKey={`ticket.total`}>
                  Total * <span>Includes all taxes</span>
                </Trans>
              </div>
              <div className='ticket-item-summary__summ'>{total} {currency}</div>
            </div>
          </div>
        </div>
        <Button
          onClickHandler={handleCheckoutBtn}
          disabled={!editOfferObj?.selectedDate}
          label={editOfferObj?.selectedDate ? t('ticket.checkout') : t('ticket.selectDate')}
          fullWidth
          color='blue'
          size='large'
        />
      </div>
    </div>
  );
};

export default Offer;
