import * as React from 'react'
const CompanyDetails = () => {
    return <div className='legal-text-container'>
        <h1>Реквизиты</h1>
        <h2>Агент:</h2>
        <p>Наименование организации: ИП Усанова Гузель Рафаилевна</p>
        <p>ИНН: 165909183418</p>
        <p>ОКПО: 0175665362</p>
        <p>ОГРНИП: 310169030600185</p>
        <p>Банк: МОСКОВСКИЙ ФИЛИАЛ АО КБ "МОДУЛЬБАНК"</p>
        <p>БИК: 044525092</p>
        <p>к/с №: 30101810645250000092</p>
        <p>Счёт №: 40802810070010366861</p>
        <h2>Оператор:</h2>
        <p>Наименование организации: Dubai Relax Travels LLC</p>
        <p>Адрес: Churchill Tower, 614, BusinessBay, Dubai</p>
        <p>Банк: Emirates NBD Bank</p>        
    </div>
}
export default CompanyDetails