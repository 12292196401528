import * as React from 'react'
const ReturnPolicy = () => {
    return <div className='legal-text-container'>
        <h1>Условия возврата</h1>
        <h2>Возврат по инициативе Покупателя</h2>
        <p>Условия возврата представлены в описании Экскурсии в разделе "Условия возврата"</p>
        <p>В том случае, если в описании Экскурсии отсутствуют условия возврата, действуют условия возврата по умолчанию:</p>
        <ul>
            <li>В случае, если до начала экскурсии осталось более 48 часов, возврат осуществляется в полном объеме за вычетом фактически понесенных исполнителем расходов, которые не могут превышать сумму Сервисного сбора.</li>
            <li>В случае отмены менее чем за 48 часов, но более чем за 24 часа, возврат осуществляется в размере 50% от стоимости за вычетом фактически понесенных исполнителем расходов, которые не могут превышать сумму Сервисного сбора</li>
            <li>В случае отмены менее чем за 24 часа, стоимость Экскурсии не возвращается.</li>
            <li>В случае не явки Покупателя, стоимость Экскурсии не возвращается</li>
            <li>В случае, если сделал ошибку в контактных данных и не сообщил об этом Исполнителю, стоимость Экскурсии не возвращается</li>
        </ul>
        <p>Условия возврата, в случае уменьшения количества участников, аналогичны условиям полной отмены Экскурсии</p>
        <p>В случае отмены Экскурсии, включающей билеты в музеи, парки аттракционов и на прочие культурно массовые мероприятия, если в Электронном билете присутствует информация, позволяющая Покупателю воспользоваться Услугой после возврата, стоимость Экскурсии не возвращается. В данной ситуации условия возврата могут быть согласованы с Исполнителем исключительно в индивидуальном порядке</p>

        <h2>Отмена Экскурсии по вине Организатора или Исполнителя</h2>
        <p>Если Организатор не может провести Экскурсию, вы получите полный возврат в размере 100% от суммы </p>
        <p>Если Исполнитель по каким либо причинам не оповестил Организатора об Экскурсии, вы получите возврат в размере 100% от суммы</p>

        <h2>Для отмены/изменения/переноса Экскурсии</h2>
        <p>Вы можете напрямую связаться с Организатором через email, написав письмо на <a href="mailto:web@dubairelaxtours.ru">web@dubairelaxtours.ru</a> или отправив сообщение в <a href='https://api.whatsapp.com/send?phone=%2B971528996301'>WhatsApp</a> или воспользоваться кнопкой в Электронном Билете, который вы получили на email</p>
        <p>Дата и время запроса будет определяться временем получения сообщения Организатором</p>
    </div>
}
export default ReturnPolicy