import React, { ReactElement, useLayoutEffect, useRef, useState } from 'react';

export type AccordeonItemType = {
  title: string;
  body: string | (() => ReactElement);
};

export type AccordeonProps = {
  items: AccordeonItemType[];
  opened?: boolean
};

const Accordion = ({ items, opened }: AccordeonProps) => {
  const [active, setActive] = useState(opened ? 0 : -1);
  return (
    <div className='accordeon-wrapper'>
      {items.map((item, index) => (
        <AccordionItem item={item} key={index} isActive={index === active} setActive={() => setActive(active === index ? -1 : index)} />
      ))}
    </div>
  );
};

const AccordionItem = ({setActive, item, isActive}: {setActive: () => void, item: AccordeonItemType, isActive: boolean}) => {
  const ref = useRef(null);
  const onClick = () => {
    setActive()
  }
  useLayoutEffect(() => {
    if(isActive)
      // @ts-ignore
      ref?.current?.scrollIntoView({block: "center"})
  }, [isActive])
  return (
    <div className='accordeon-item-wrap' >
      <div
        ref={ref}
        onClick={onClick}
        className={`accordeon-item-title ${isActive ? 'accordeon-item-title_active' : ''}`}
      >
        <svg xmlns='http://www.w3.org/2000/svg' width='7' height='11' viewBox='0 0 7 11'>
          <path
            d='M2.021,5.121l3.83-3.83a.561.561,0,0,0,0-.792L5.515.164a.561.561,0,0,0-.792,0L.163,4.723a.565.565,0,0,0,0,.795l4.556,4.555a.561.561,0,0,0,.792,0l.335-.335a.561.561,0,0,0,0-.792Z'
            transform='translate(6.014 10.237) rotate(180)'
          ></path>
        </svg>
        {item.title}
      </div>
      <div className={`accordeon-item-content ${isActive ? 'accordeon-item-content_active' : ''}`}>
        {typeof item.body === 'function' ? item.body() : item.body}
      </div>
    </div>
  )
}
export default Accordion;
