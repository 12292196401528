import React, { useEffect } from 'react';
import MyRoutes from './containers/router';
import './assets/styles/app.sass';
import ViewportProvider from './contexts/viewport-context';
import { useTranslation } from 'react-i18next';
import { getLanguage } from './config/constants';
import CurrencyProvider from './contexts/currencyContext';

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLanguage())
  }, [i18n])

  return (
    <ViewportProvider>
      <CurrencyProvider>
        <MyRoutes />
      </CurrencyProvider>
    </ViewportProvider>
  );
}

export default App;
