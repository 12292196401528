import { getLanguage  } from "../../config/constants";
import FooterDefault from "./footerDefault";
import FooterRu from "./footerRu";

const Footer = () => {
  const language = getLanguage()
  console.log(language)
  switch (language) {
    case 'ru':
      return <FooterRu/>
    default:
      return <FooterDefault/>
  }
  
}

export default Footer;
