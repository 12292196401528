import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Offer from './offer';
import { ticketApi } from '../../lib/api';
import { TicketType } from '../../types/ticket';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { CheckoutData } from '../checkout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getLanguage, routes, getTourId } from '../../config/constants';
import isEqual from 'date-fns/isEqual';
import { startOfDay } from 'date-fns';
import { Availability } from '../../types/availability';
import availabilityApi from '../../lib/api/availability';
import { useTranslation } from 'react-i18next';
import ImgWithFallback from '../../components/ImgWithFallback';
import Button from '../../components/button';
import { Helmet } from 'react-helmet';
import Accordion, { AccordeonItemType } from '../../components/accordeon';
import Carousel from '../../components/image-carousel';
import Fancybox from '../../lib/fancybox';
import Video from '../../components/video';
import { CurrencyContext } from '../../contexts/currencyContext';
import Cities from '../../components/cities';
import { City } from '../../types/city';
import citiesApi from '../../lib/api/cities';
import useViewport from '../../hooks/use-viewport';


const emptyOfferObj = {
  offer: undefined,
  ticketName: '',
  adultCount: 1,
  childCount: 0,
  infantCount: 0
};

const Ticket = () => {
  const [t] = useTranslation();

  const [ticket, setTicket] = useState<TicketType>();
  const [editOfferObj, setEditOfferObj] = useState<CheckoutData>(emptyOfferObj);
  const [availabilities, setAvailabilities] = useState<Availability[]>([]);
  const [selectedCity, setSelectedCity] = useState<City>({value: '-1', label: t('citySelect.any')});
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const handleEventHeaderClick = useCallback(() => {
      navigate(-1);
  }, [navigate]);


  const { currency } = useContext(CurrencyContext);

  const [cities, setCities] = useState<City[]>();
  useEffect(() => {
    const fetch = async () => {
      const data = await citiesApi.getCities(50, getLanguage());
      setCities(data);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await ticketApi.getTickets(getTourId() || params?.id, currency);
      setTicket(data);
    };
    fetchData();
    document.addEventListener('eventHeaderClick', handleEventHeaderClick, false);
    return () => document.removeEventListener('eventHeaderClick', handleEventHeaderClick, false);
  }, [handleEventHeaderClick, params?.id, currency]);

  useEffect(() => {
    // Back params from checkout
    if (location.state) {
      const data = location.state as CheckoutData;
      setEditOfferObj(data);
    }
  }, [location.state]);

  const handleChangeField = useCallback((value: any, name: string) => {
    setEditOfferObj((prev) => {
      if (prev) {
        let newState = { ...prev };
        // @ts-ignore
        newState[name] = value;
        return newState;
      }
      console.log('handleChangeField prev is null');
      return emptyOfferObj;
    });
  }, []);

  const loadMoreDates = useCallback(() => {
    const fetchData = async () => {
      if (editOfferObj?.offer) {
        const data = await ticketApi.getOffersPrices(
          editOfferObj.offer.id,
          new Date(editOfferObj.offer.prices[editOfferObj.offer.prices.length - 1].date),
          currency
        );

        setEditOfferObj((prev) => {
          const result = { ...prev };

          if (result.offer?.prices) {
            result.offer.prices = result.offer.prices
              .concat(data)
              .filter((value, index, self) => self.findIndex((f) => f.date === value.date) === index)
              .sort((a, b) => (a.date > b.date ? 1 : -1));
          }
          return result;
        });
      }
    };
    fetchData();
  }, [editOfferObj.offer, currency]);

  useEffect(() => {
    if (editOfferObj.offer && editOfferObj.selectedDate) {
      const price = editOfferObj.offer?.prices.find(
        (price) => editOfferObj.selectedDate && isEqual(startOfDay(editOfferObj.selectedDate), startOfDay(new Date(price.date))));
      if (price) {
        handleChangeField(price, 'selectedPrice');
      } else {
        loadMoreDates();
      }
    }
  }, [editOfferObj.offer, editOfferObj.offer?.prices, editOfferObj.selectedDate, handleChangeField, loadMoreDates]);

  const fetchAvailability = useCallback(async () => {
    if(editOfferObj.offer){
      const data = await availabilityApi.getByOfferId(
        editOfferObj.offer.id, 
        startOfDay(editOfferObj.selectedDate || new Date())
      );
    // const data = await availabilityApi.getByTimeSlots(
    //   editOfferObj.offer?.timeSlots.map((slot) => slot.id) ||
    //   (editOfferObj.timeSlot && [editOfferObj.timeSlot.id]),
    //   startOfDay(editOfferObj.selectedDate || new Date())
    // );
      setAvailabilities((prevState) => [...prevState, ...data]);
    }
  }, [editOfferObj.offer, editOfferObj.selectedDate]);

useEffect(() => {
    if ((editOfferObj.timeSlot || editOfferObj.offer?.timeSlots.length === 0) && editOfferObj.selectedDate) {
      const availability = availabilities.find(
        (item) =>
          editOfferObj.timeSlot?.id === item.timeSlotId &&
          editOfferObj.selectedDate &&
          isEqual(startOfDay(editOfferObj.selectedDate), startOfDay(new Date(item.date)))
      );
      if (availability) {
        setEditOfferObj((prevState) => {
          const result = { ...prevState, availability: availability };
          if (availability.left < prevState.adultCount + prevState.childCount + prevState.infantCount) {
            result.adultCount = 1;
            result.childCount = 0;
            result.infantCount = 0;
          }
          if (availability.left < 1) {
            const newAvailability = availabilities.find(
              (item) => prevState?.offer?.timeSlots.some((slot) => slot.id === item.timeSlotId) && item.left > 0
            );
            if (newAvailability) {
              result.availability = newAvailability;
              result.selectedDate = new Date(newAvailability.date);
              result.selectedPrice = prevState?.offer?.prices.find(
                (price) =>
                  result.selectedDate && isEqual(startOfDay(result.selectedDate), startOfDay(new Date(price.date)))
              );
            }
          }
          return result;
        });
      } else {
        fetchAvailability();
      }
    } else if (editOfferObj.offer && !availabilities.length) {
      fetchAvailability();
    }
  }, [availabilities, editOfferObj.offer, editOfferObj.selectedDate, editOfferObj.timeSlot, fetchAvailability]);

  const handleCheckoutBtn = useCallback(() => navigate(routes.checkout, { state: editOfferObj }), [editOfferObj, navigate]);

  const handleSelectOffer = useCallback(
    (id: number) => {
      setEditOfferObj((prev) => {
        if (id === prev?.offer?.id) {
          return { ...prev, offer: undefined };
        } else {
          const offer = ticket?.offers.find((offer) => offer.id === id);
          const result = {
            ...prev,
            ticketName: ticket?.name || '',
            tourId: ticket?.id,
            tourUrl: getTourId() || params?.id,
            offer: offer,
            timeSlot: offer?.timeSlots[0],
            availability: availabilities.find(
              (item) =>
                offer?.timeSlots.some((slot) => slot.id === item.timeSlotId) &&
                prev.selectedDate &&
                isEqual(startOfDay(prev.selectedDate), startOfDay(new Date(item.date)))
            ),
            faq: ticket?.faq,
            cancellationPolicy: ticket?.cancellationPolicy
          };
          if (prev.selectedDate && (!result.availability || result.availability.left < 1)) {
            const newAvailability = availabilities.find(
              (item) => offer?.timeSlots.some((slot) => slot.id === item.timeSlotId) && item.left > 0
            );
            if (newAvailability) {
              result.availability = newAvailability;
              result.selectedDate = new Date(newAvailability.date);
            }
          }
          result.selectedPrice = offer?.prices.find(
            (price) =>
              result.selectedDate && isEqual(startOfDay(result.selectedDate), startOfDay(new Date(price.date)))
          );

          if (
            prev.offer &&
            offer &&
            (prev.offer.maxNumOfPeople > offer.maxNumOfPeople ||
              (result.availability &&
                result.availability.left < result.adultCount + result.childCount + result.infantCount))
          ) {
            result.adultCount = 1;
            result.childCount = 0;
            result.infantCount = 0;
          }
          return result;
        }
      });
    },
    [ticket, availabilities, params?.id]
  );


  const photoItems = ticket?.photos.map(item => <ImgWithFallback
    data={item.srcSet || item?.srcSet}
    alt={item.caption || item?.caption}
    width={1280}
  />) || [];

  const ticketRef = useRef(null);

  const filteredTickets = useMemo(() => ticket?.offers.filter(offer => selectedCity.value === '-1' ? true : offer.cities.includes(selectedCity.value)), [selectedCity, ticket])

  const sortedTickets = useMemo(() => filteredTickets?.sort((a, b) => {
    if (a.prices.length && b.prices.length){
      if(a.prices[0]?.pricePerTrip > 0 && b.prices[0]?.pricePerTrip > 0)
        return a.prices[0]?.pricePerTrip <= b.prices[0]?.pricePerTrip ? -1 : 1
      if(a.prices[0]?.pricePerTrip > 0)
        return 1
      if(b.prices[0]?.pricePerTrip > 0)
        return -1
      return a.prices[0]?.pricePerAdult <= b.prices[0]?.pricePerAdult ? -1 : 1
    }
    return -1;
  }), [filteredTickets]);

  const accordionItems = useMemo((): AccordeonItemType[] => {
    const result = [];
    if (ticket) {
      if (ticket.salesDescription)
        result.push({
          title: t('ticket.salesDescription'), body: () => (<div
            dangerouslySetInnerHTML={{ __html: ticket.salesDescription }}></div>)
        });
      if (ticket.photos)
        result.push({
          title: t('ticket.gallery'), body: () => (
            <div className='gallery'>
              <Fancybox>
                {ticket.photos.map(item => <a data-fancybox='gallery'
                                              href={item?.srcSet[1].sizes.find(x => x.width === 1920)?.src || ''}><ImgWithFallback
                  data={item.srcSet || item?.srcSet}
                  alt={item.caption || item?.caption}
                  width={320}
                /></a>)}
              </Fancybox>
            </div>
          )
        });
      if (ticket.videoUrl)
        result.push({
          title: t('ticket.video'), body: () => <Video videoUrl={ticket.videoUrl} />
        });
      if (ticket.tourItinerary)
        result.push({
          title: t('ticket.tourItinerary'), body: () => (<div
            dangerouslySetInnerHTML={{ __html: ticket.tourItinerary }}
          ></div>)
        });
      if (ticket.faq)
        result.push({
          title: t('ticket.faq'), body: () => (<div
            dangerouslySetInnerHTML={{ __html: ticket.faq }}
          ></div>)
        });
      if (ticket.termsAndConditions)
        result.push({
          title: t('ticket.terms'), body: () => (<div
            dangerouslySetInnerHTML={{ __html: ticket.termsAndConditions }}
          ></div>)
        });
    }
    return result;
  }, [t, ticket]);

  // @ts-ignore
  const executeScroll = () => ticketRef && ticketRef.current && ticketRef.current.scrollIntoView();

  const { adultPrice, childPrice, perTrip } = useMemo(() => {
    const minTicket = sortedTickets?.length && sortedTickets[0];
    if (minTicket)
      return { adultPrice: minTicket.prices[0].pricePerAdult, childPrice: minTicket.prices[0].pricePerChild, perTrip: minTicket.prices[0].pricePerTrip };
    return { adultPrice: 0, childPrice: 0, perTrip: 0 };
  }, [sortedTickets]);

  const [isViewButton, setIsViewButton] = useState(true);
  const {height} = useViewport();

  useEffect(() => {
    const fn = () => {
      // @ts-ignore
      setIsViewButton(window.pageYOffset + height - 150 < ticketRef.current?.offsetTop);
    }

    document.addEventListener('scroll', fn);
    return document.addEventListener('scroll', fn);
  }, [height])


  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{ticket?.seoPageTitle}</title>
        <meta name='description' content={ticket?.seoPageDescription} />
        <meta name='content' content={ticket?.seoContent} />
        <link rel='canonical' href='http://mysite.com/example' />
      </Helmet>

      <div className='content-wrap content-travel-ticket-wrap content-with-sidebar'>
        <div className='content-part'>
          <div className='content-description-block'>
            {ticket?.categories?.map((category,index) => <span key={index} className='category-label'>{index > 0 ? ', ' : ''}{category.name}</span>)}
            <h2>{ticket?.name}</h2>
          </div>
          <div className='one-image-slider-wrap'>
            <Carousel items={photoItems} />
          </div>
          <Accordion items={accordionItems} />
        </div>
        <aside className='sidebar-part'>
          <div className='widget-ticket-wrap'>
            <div className='widget-ticket'>
              <span>{t('ticket.startingFrom')}</span>
              <div className='widget-ticket__prices'>
                {!!adultPrice && <div className='widget-ticket__prices-adult'>
                  {adultPrice.toFixed()} {currency}
                  <span>{t('adult')}</span>
                </div>}
                {!!childPrice && <div className='widget-ticket__prices-child'>
                  {childPrice.toFixed()} {currency}
                  <span>{t('child')}</span>
                </div>}
                {!!perTrip && <div className='widget-ticket__prices-child'>
                {perTrip.toFixed()} {currency}
                <span>{t('ticket.perCar')}</span>
              </div>}
              </div>
              <div className='widget-ticket__seperator'>
                <svg style={{ display: 'block' }} xmlns='http://www.w3.org/2000/svg' width='336' height='3'
                     viewBox='0 0 336 3'>
                  <g id='Group_165' data-name='Group 165' transform='translate(-779 -48)' opacity='0.18'>
                    <circle id='Ellipse_80' data-name='Ellipse 80' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(779 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_81' data-name='Ellipse 81' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(788 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_82' data-name='Ellipse 82' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(797 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_83' data-name='Ellipse 83' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(806 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_84' data-name='Ellipse 84' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(815 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_85' data-name='Ellipse 85' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(824 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_86' data-name='Ellipse 86' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(833 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_99' data-name='Ellipse 99' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(842 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_100' data-name='Ellipse 100' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(851 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_101' data-name='Ellipse 101' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(860 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_102' data-name='Ellipse 102' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(869 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_103' data-name='Ellipse 103' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(878 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_104' data-name='Ellipse 104' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(887 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_105' data-name='Ellipse 105' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(896 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_106' data-name='Ellipse 106' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(905 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_107' data-name='Ellipse 107' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(914 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_108' data-name='Ellipse 108' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(923 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_109' data-name='Ellipse 109' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(932 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_110' data-name='Ellipse 110' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(941 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_111' data-name='Ellipse 111' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(950 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_112' data-name='Ellipse 112' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(959 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_113' data-name='Ellipse 113' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(968 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_114' data-name='Ellipse 114' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(977 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_115' data-name='Ellipse 115' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(986 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_116' data-name='Ellipse 116' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(995 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_117' data-name='Ellipse 117' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(1004 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_118' data-name='Ellipse 118' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(1013 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_119' data-name='Ellipse 119' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(1022 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_120' data-name='Ellipse 120' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(1031 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_121' data-name='Ellipse 121' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(1040 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_122' data-name='Ellipse 122' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(1049 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_123' data-name='Ellipse 123' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(1058 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_124' data-name='Ellipse 124' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(1067 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_125' data-name='Ellipse 125' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(1076 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_126' data-name='Ellipse 126' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(1085 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_127' data-name='Ellipse 127' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(1094 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_128' data-name='Ellipse 128' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(1103 48)' fill='#f5f6fa' />
                    <circle id='Ellipse_129' data-name='Ellipse 129' cx='1.5' cy='1.5' r='1.5'
                            transform='translate(1112 48)' fill='#f5f6fa' />
                  </g>
                </svg>
              </div>
              <Button label={t('bookNow')} onClickHandler={executeScroll} customClassName='widget-ticket__button '
                      color='green'>

              </Button>
            </div>
          </div>
        </aside>
      </div>
      <div>
        <div ref={ticketRef} className='ticket-select-label'>
          <div>{t('ticket.select')}</div>
          <div><Cities cities={cities?.filter(city => ticket?.offers.find(offer => offer.cities.includes(city.value)))} value={selectedCity} onChange={setSelectedCity}/></div>
        </div>
        {ticket ? (
          <div className='ticket-wrap'>
            {sortedTickets?.length ? sortedTickets.map((offer) => (
              <Offer
                key={offer.id}
                offer={offer}
                isSelected={offer.id === editOfferObj.offer?.id}
                selectHandler={handleSelectOffer}
                heroImage={ticket.heroImage}
                editOfferObj={editOfferObj}
                handleCheckoutBtn={handleCheckoutBtn}
                handleChangeField={handleChangeField}
                availabilities={availabilities}
                loadMoreDates={loadMoreDates}
                utcOffset={ticket.utcOffset}
                cities={cities?.filter(city => offer.cities.includes(city.value)).map(option => option.label)}
              />
            )): <span>{t('ticket.notTicket')}</span>}
          </div>) : null}
      </div>

      {isViewButton ? <div className='widget-ticket widget-ticket-fixed'>
        <Button label={t('bookNow')} fullWidth color='green' onClickHandler={executeScroll} />
      </div> : <></>}
    </>
  );
};

export default Ticket;
